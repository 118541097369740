import { Stack, Typography } from "@mui/material";
import WalletConnectProvider from "@walletconnect/web3-provider";
import React from "react";
import walletconnect from "../../assets/images/walletconnect.svg";
import { Web3Provider } from "@ethersproject/providers";
import { useState } from "react";
import { toast } from "react-hot-toast";

const WalletConnectWallet = ({ setWallet, closeModal }) => {
  const [account, setAccount] = useState();
  const [signer, setSigner] = useState();
  const onConnect = async () => {
    try {
      const RPC_URLS = {
        1: "https://mainnet.infura.io/v3/32e2849081004fb7a72f5b55200df426",
        2: "https://rpc-mumbai.maticvigil.com/",
        3: "https://arbitrum-mainnet.infura.io",
        4: "https://aurora-mainnet.infura.io"
      };
      const provider = new WalletConnectProvider({
        rpc: {
          1: RPC_URLS[1],
          80001: RPC_URLS[2]
        },
        qrcode: true
      });
      await provider.enable();
      // const account = accounts[0];

      const library = new Web3Provider(provider, "any");
      const signerAddress = library.getSigner();
      const { chainId } = await library.getNetwork();
      if (chainId === 1) {
        const signedMessage = await signerAddress.signMessage(
          `Welcome to KATANA  ${Date.now().toString()}`
        );
        setWallet(provider?.accounts[0]);
        closeModal(false);
        setSigner(signerAddress);
      } else {
        toast.error("Change network to Ethereum Mainnet");
      }
    } catch (error) {
      //console.log(error);
      toast.error("User rejected signature");
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        className="button"
        onClick={onConnect}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          p: "1em",
          cursor: "pointer"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img src={walletconnect} alt="" width={"10%"} />
        <Typography height={"fit-content"} variant={"h4"}>
          WalletConnect
        </Typography>
      </Stack>
    </>
  );
};

export default WalletConnectWallet;
