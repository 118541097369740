import React from 'react'
import PinInput from 'react-pin-input'

const OTPInput = ({ handlePin }) => {
  return (
    <PinInput
      length={6}
      initialValue=""
      type="numeric"
      inputMode="number"
      style={{
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
      inputStyle={{
        color: 'white',
        borderColor: 'gray'
      }}
      inputFocusStyle={{ borderColor: '#1665C0' }}
      onComplete={(value, index) => handlePin(value)}
    />
  )
}

export default OTPInput
