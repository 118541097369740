import { config, passport } from "@imtbl/sdk";

const passportInstance = new passport.Passport({
  baseConfig: {
    environment: config.Environment.PRODUCTION, // or Environment.PRODUCTION
    publishableKey: "pk_imapik-YoaiOijmmM-cPB5zUvSD" // replace with your publishable API key from Hub
  },
  clientId: "atN4W8WWOXezpcfSkGKbx4UAPhmbVIZ2", // replace with your client ID from Hub
  redirectUri: "https://launcher.katanainu.com/", // replace with one of your redirect URIs from Hub
  logoutRedirectUri: "https://launcher.katanainu.com/", // replace with one of your logout URIs from Hub
  audience: "platform_api",
  scope: "openid offline_access email transact",
  popupOverlayOptions: {
    disableGenericPopupOverlay: false, // Set to true to disable the generic pop-up overlay
    disableBlockedPopupOverlay: false // Set to true to disable the blocked pop-up overlay
  }
});
export default passportInstance;
