import axios from "axios";
import isElectron from "is-electron";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { googelLoginUser } from "../store/auth/actions";

const DiscordCallbackScreen = () => {
  const [resData, setResData] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const fetchDiscordUser = code => {
      return axios
        .post("https://launcherapi.katanainu.com/api/v1/signInWithDiscord", {
          code
        })
        .then(
          response => {
            setResData(response.data.data.oauthData);
          },
          error => {
            //console.log(error);
          }
        );
    };
    fetchDiscordUser(code);
    if (isElectron()) {
      window.ipcRenderer.on("discord-login-success", (event, data) => {
        const mail = data?.data?.split("response=?")[1].split("&")[0];

        dispatch(googelLoginUser({ email: mail, navigate }));
      });
    }
  }, []);

  useEffect(() => {
    if (resData) {
      axios
        .get("https://discordapp.com/api/users/@me", {
          headers: { Authorization: `Bearer ${resData.access_token}` }
        })
        .then(
          response => {
            if (!response.hasOwnProperty("error")) {
              const link = document.createElement("a");
              link.href = `katana-inu://response=?${response.data.email}&authtype=discord-auth`;
              document.body.appendChild(link);
              link.click();
            }
            dispatch(googelLoginUser({ email: response.data.email, navigate }));
          },
          error => {
            //console.log(error);
          }
        );
    }
  }, [resData]);

  return <div>Singin In .....</div>;
};

export default DiscordCallbackScreen;
