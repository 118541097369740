import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  CLEAR_DATA,
  FORGET_PASSWORD,
  GET_NFTS,
  GOOGLE_LOGIN,
  LOGIN,
  PIN,
  RESET_PASSWORD,
  SIGNUP,
  SIGNUP_OTP,
  UPDATE,
  VALIDATE_KEY,
  VALIDATE_USER
} from "./actionTypes";
import {
  stopLoading,
  loginSuccess,
  startLoading,
  pinSuccess,
  errorHandler,
  connectWalletSuccess,
  clearDataSuccess,
  signUpSuccess,
  signUpOtpSuccess,
  getNftsSuccess,
  validateKeySuccess,
  startNftLoading,
  stopNftLoading,
  startKeyLoading,
  stopKeyLoading,
  googleLoginSuccess,
  getNftsFailed,
  validateUserSuccess,
  forgetPasswordSuccess,
  resetPasswordSuccess
} from "./actions";
import isElectron from "is-electron";

function* login({ payload }) {
  try {
    yield put(startLoading());
    let response;
    if (isElectron()) {
      console.log(payload, "isElectron=>");
      response = yield axios.post("login", {
        email: payload.email,
        password: payload.password,
        version: payload?.version
      });
    } else {
      console.log(payload, "else part=>");

      response = yield axios.post("login", {
        email: payload.email,
        password: payload.password
      });
    }
    yield put(loginSuccess(response.data));
    // const data = {
    //   username: payload?.email,
    //   password: payload?.password,
    //   grant_type: "password",
    //   scope: "katana-game-api openid profile offline_access"
    // };
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/x-www-form-urlencoded",
    //     Authorization: "Basic R2FtZUNsaWVudDo="
    //   },
    //   data: qs.stringify(data),
    //   url: "https://auth-staging.katanainu.com/connect/token"
    // };
    // let EOS_login = yield axioss(options);
    // yield put(loginSuccessEOS(EOS_login));
    payload.navigate("/");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* forgetPassword({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/admin/forgetPassword", {
      email: payload.email
    });
    yield put(forgetPasswordSuccess(response.data));
    payload.nav("/");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post(
      `/admin/resetPassword?password=${payload?.password}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${payload?.token}`
        }
      }
    );
    yield put(resetPasswordSuccess(response.data));
    payload.nav("/");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* validateUser({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("/users/validateUser", {
      headers: { Authorization: `Bearer ${payload.token}` },
      params: { version: payload?.version }
    });
    yield put(validateUserSuccess(response.data));
    payload.navigate("/");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* googelLogin({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("signinWithGoogle", {
      email: payload.email,
      signupMethod: "Google"
    });
    yield put(googleLoginSuccess(response.data));

    payload.navigate("/");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* signup({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("signUp", payload);
    yield put(signUpSuccess(response.data));
    // const config = {
    //   header: {
    //     "Content-Type": "application/json",
    //     Accept: "*/*"
    //   }
    // };

    // const signupEOS = yield axios.post(
    //   "https://auth-staging.katanainu.com/api/user",
    //   payload,
    //   config
    // );
    // yield put(signUpSuccessEOS(signupEOS));
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* signupOtp({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("validate", { otp: payload.otp });
    yield put(signUpOtpSuccess(response.data));
    payload.navigate("/signin");
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* connectWallet({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("updateProfile", {
      walletAddress: payload.walletAddress
    });

    yield put(connectWalletSuccess(response.data));
    if (response) {
      payload.socket.emit("connectMetaMask", {
        Authorization: `Bearer ${payload?.token}`,
        type: "web"
      });
    }
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* getPin({ payload }) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + payload.token;
  try {
    yield put(startLoading());
    const response = yield axios.post("validate", { otp: payload.otp });
    yield put(pinSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopLoading());
  }
}

function* getNftsAction({ payload }) {
  const wallet = payload?.walletAddress?.walletAddress;
  try {
    yield put(startNftLoading());
    const response = yield axios.post("users/getNFTS", {
      walletAddress: wallet
    });
    yield put(getNftsSuccess(response.data));
  } catch (error) {
    yield put(getNftsFailed(error));
  } finally {
    yield put(stopNftLoading());
  }
}

function* validateKeyAction({ payload }) {
  try {
    yield put(startKeyLoading());
    const response = yield axios.get(`keys/checkKey?key=${payload.key}`);
    yield put(validateKeySuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(stopKeyLoading());
  }
}

function* clearTokenData({ payload }) {
  try {
    yield put(clearDataSuccess(payload));
  } catch (error) {
    //console.log(error);
  }
}
function* watchClearData() {
  yield takeLatest(CLEAR_DATA, clearTokenData);
}

function* watchLogin() {
  yield takeLatest(LOGIN, login);
}

function* watchforgetPassword() {
  yield takeLatest(FORGET_PASSWORD, forgetPassword);
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

function* watchValidateUser() {
  yield takeLatest(VALIDATE_USER, validateUser);
}
function* watchGoogelLogin() {
  yield takeLatest(GOOGLE_LOGIN, googelLogin);
}

function* watchSignUp() {
  yield takeLatest(SIGNUP, signup);
}

function* watchSignUpOtp() {
  yield takeLatest(SIGNUP_OTP, signupOtp);
}

function* watchPin() {
  yield takeLatest(PIN, getPin);
}

function* watchGetNft() {
  yield takeLatest(GET_NFTS, getNftsAction);
}

function* watchValidateKey() {
  yield takeLatest(VALIDATE_KEY, validateKeyAction);
}

function* watchConnectWallet() {
  yield takeLatest(UPDATE, connectWallet);
}

export default function* authSaga() {
  yield all([
    fork(watchPin),
    fork(watchLogin),
    fork(watchforgetPassword),
    fork(watchResetPassword),
    fork(watchGoogelLogin),
    fork(watchSignUp),
    fork(watchSignUpOtp),
    fork(watchGetNft),
    fork(watchValidateKey),
    fork(watchClearData),
    fork(watchConnectWallet),
    fork(watchValidateUser)
  ]);
}
