import {
  LOGIN,
  STOP_LOADING,
  START_LOADING,
  LOGIN_SUCCESS,
  PIN,
  PIN_SUCCESS,
  ERROR,
  CLEAR_DATA,
  UPDATE,
  UPDATE_WALLET,
  CLEAR_DATA_SUCCESS,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_OTP,
  SIGNUP_OTP_SUCCESS,
  GET_NFTS,
  GET_NFTS_SUCCESS,
  GET_NFTS_FAILED,
  VALIDATE_KEY,
  VALIDATE_KEY_SUCCESS,
  VALIDATE_KEY_ERROR,
  NFT_START_LOADING,
  NFT_STOP_LOADING,
  KEY_START_LOADING,
  KEY_STOP_LOADING,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  SET_SOCKET_SUCCESS,
  LOGOUT,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGNUP_OTP_RESET,
  SET_WALLET_ADDRESS,
  SIGNUP_EOS,
  SIGNUP_SUCCESS_EOS
} from "./actionTypes";

export const loginUser = data => {
  return {
    type: LOGIN,
    payload: data
  };
};

export const loginSuccess = data => {
  return {
    type: LOGIN_SUCCESS,
    payload: data
  };
};

// //login to EOS
// export const loginEOS = data => {
//   return {
//     type: LOGIN_EOS,
//     payload: data
//   };
// };
// //login to EOS success
// export const loginSuccessEOS = data => {
//   return {
//     type: LOGIN_SUCCESS_EOS,
//     payload: data
//   };
// };

export const forgetPassword = data => {
  return {
    type: FORGET_PASSWORD,
    payload: data
  };
};

export const forgetPasswordSuccess = data => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data
  };
};

export const resetPassword = data => {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
};

export const resetPasswordSuccess = data => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data
  };
};

export const validateUser = data => {
  return {
    type: VALIDATE_USER,
    payload: data
  };
};

export const validateUserSuccess = data => {
  return {
    type: VALIDATE_USER_SUCCESS,
    payload: data
  };
};

export const googelLoginUser = data => {
  return {
    type: GOOGLE_LOGIN,
    payload: data
  };
};

export const logoutUser = data => {
  return {
    type: LOGOUT,
    payload: data
  };
};

export const googleLoginSuccess = data => {
  return {
    type: GOOGLE_LOGIN_SUCCESS,
    payload: data
  };
};
// //signUpUser EOS
// export const signUpEOS = data => {
//   return {
//     type: SIGNUP_EOS,
//     payload: data
//   };
// };
// //signUpSuccess EOS
// export const signUpSuccessEOS = data => {
//   return {
//     type: SIGNUP_SUCCESS_EOS,
//     payload: data
//   };
// };

export const signUpUser = data => {
  return {
    type: SIGNUP,
    payload: data
  };
};

export const signUpSuccess = data => {
  return {
    type: SIGNUP_SUCCESS,
    payload: data
  };
};

export const signUpUserOtp = data => {
  return {
    type: SIGNUP_OTP,
    payload: data
  };
};

export const signUpUserOtpReset = () => {
  return {
    type: SIGNUP_OTP_RESET
  };
};
export const signUpOtpSuccess = data => {
  return {
    type: SIGNUP_OTP_SUCCESS,
    payload: data
  };
};

export const connectWallet = data => {
  return {
    type: UPDATE,
    payload: data
  };
};

export const connectWalletSuccess = data => {
  return {
    type: UPDATE_WALLET,
    payload: data
  };
};

export const getPin = data => {
  return {
    type: PIN,
    payload: data
  };
};

export const pinSuccess = data => {
  return {
    type: PIN_SUCCESS,
    payload: data
  };
};

export const getNfts = data => {
  return {
    type: GET_NFTS,
    payload: data
  };
};

export const getNftsSuccess = data => {
  return {
    type: GET_NFTS_SUCCESS,
    payload: data
  };
};

export const getNftsFailed = data => {
  return {
    type: GET_NFTS_FAILED,
    payload: data
  };
};

export const setSocketSuccess = data => {
  return {
    type: SET_SOCKET_SUCCESS,
    payload: data
  };
};

export const validateKey = data => {
  return {
    type: VALIDATE_KEY,
    payload: data
  };
};

export const validateKeySuccess = data => {
  return {
    type: VALIDATE_KEY_SUCCESS,
    payload: data
  };
};

export const validateKeyError = data => {
  return {
    type: VALIDATE_KEY_ERROR,
    payload: data
  };
};

export const startLoading = data => ({
  type: START_LOADING,
  payload: data
});

export const stopLoading = data => ({
  type: STOP_LOADING,
  payload: {
    action: data
  }
});

export const startNftLoading = data => ({
  type: NFT_START_LOADING,
  payload: data
});

export const stopNftLoading = data => ({
  type: NFT_STOP_LOADING,
  payload: {
    action: data
  }
});

export const startKeyLoading = data => ({
  type: KEY_START_LOADING,
  payload: data
});

export const stopKeyLoading = data => ({
  type: KEY_STOP_LOADING,
  payload: {
    action: data
  }
});

export const clearData = data => ({
  type: CLEAR_DATA,
  payload: {
    action: data
  }
});

export const clearDataSuccess = data => ({
  type: CLEAR_DATA_SUCCESS,
  payload: {
    action: data
  }
});

export const errorHandler = data => ({
  type: ERROR,
  payload: data
});

export const setWalletAddress = data => {
  //console.log("Logging data:", data); // Add this line to log the data
  return {
    type: SET_WALLET_ADDRESS,
    payload: {
      action: data
    }
  };
};
