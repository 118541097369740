import React from "react";
import { Stack } from "@mui/system";
import SideBar from "../components/login/SideBar";
import Header from "../components/login/Header";
import background from "../assets/images/Background.svg";
import { Typography } from "@mui/material";
import packageJson from "../../package.json";
import isElectron from "is-electron";

const Layout = ({ children }) => {
  // Display the current version

  // Listen for messages

  return (
    <Stack
      direction={"row"}
      height={"100%"}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <SideBar />
      <Stack flexGrow={1} color={"white"}>
        <Header />
        {children}
        <Stack
          alignItems={"flex-end"}
          paddingBottom={"2.5vw"}
          paddingRight={"2vw"}
        >
          {isElectron() && (
            <Typography color={"gray"} fontSize={"1vw"} id="version">
              v{packageJson.version}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Layout;
