import React, { useEffect } from "react";
import { Stack } from "@mui/system";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import { SidebarData, SideBarSocial } from "./Data";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { IconButton, Link, Typography } from "@mui/material";
import logoKatana from "../../assets/images/logoKatana.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import isElectron from "is-electron";
import { RxAvatar } from "react-icons/rx";
import { toast } from "react-hot-toast";
import { IoMdLogOut } from "react-icons/io";
import { logoutUser, setWalletAddress } from "../../store/auth/actions";
import "../../utils/css/styles.css";
import { clearGameData } from "../../store/game/actions";

const SideBar = () => {
  const drawerWidth = 240;
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [wallet, setWallet] = useState();
  const { user, socket, walletAddress } = useSelector(state => state.auth);
  useEffect(() => {
    if (isElectron()) {
      // window.ipcRenderer.on("child-killed", () => {
      //   console.log("inside child-killed");
      //   dispatch(logoutUser());
      //   nav("/signin");
      //   toast.success("Logout");
      // });
      //console.log("🚀 ~ file: SideBar.jsx:31 ~ useEffect ~ isElectron():", isElectron())
      if (socket) {
        socket.on("removeMetaResponse", res => {
          dispatch(setWalletAddress(res.walletAddress));
          setWallet(null);
        });

        socket.on("connectMetaMaskResponse", update => {
          setWallet(update);
        });
      }
    }
  }, []);
  useEffect(() => {
    setWallet(walletAddress);
  }, [walletAddress]);

  const handleOpenLink = link => {
    if (isElectron()) {
      window.ipcRenderer.send("openHeaderLinks", {
        link
      });
    } else {
      window.open(link, "_blank");
    }
  };

  // const handleLogout = () => {
  //   // if (isElectron()) {
  //   //   window.ipcRenderer.send("cancel-on-logout");
  //   // } else {
  //   dispatch(logoutUser());
  //   nav("/signin");
  //   toast.success("Logout");
  //   // }
  // };

  return (
    <Drawer
      PaperProps={{
        sx: {
          color: "white",
          backgroundColor: "transparent"
        }
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box"
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <Stack
        paddingY={"15px"}
        alignItems={"center"}
        sx={{ borderBottom: "1px solid #434343", marginX: "16px !important" }}
      >
        <img src={logoKatana} alt="" width={"60%"} />
      </Stack>
      <Stack
        paddingX={"5px"}
        sx={{ borderBottom: "1px solid #434343", marginX: "16px !important" }}
      >
        <Stack
          spacing={2}
          paddingY={"15px"}
          paddingLeft={"8px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {user ? (
            <>
              {/* <Avatar alt="user profile" src={avatar} />*/}
              <RxAvatar size={35} />
              <Typography color={"white"}>
                HI, {user?.email?.split("@")[0].toUpperCase()}
              </Typography>
            </>
          ) : (
            <RxAvatar size={35} />
          )}
        </Stack>
        {isElectron() && wallet && (
          <Typography
            textAlign={"start"}
            sx={{ fontSize: "14px", cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(wallet);
              toast.success("Address Copied");
            }}
          >
            {`${wallet?.slice(
              0,
              10
            )}...${wallet?.slice(-10)}`}
          </Typography>
        )}
        {!isElectron() && walletAddress && (
          <Typography
            textAlign={"start"}
            sx={{ fontSize: "14px", cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(walletAddress);
              toast.success("Address Copied");
            }}
          >
            {`${walletAddress?.slice(0, 10)}...${walletAddress?.slice(-10)}`}
          </Typography>
        )}
      </Stack>
      <List>
        {SidebarData.map((item, index) => (
          <ListItem sx={{ paddingTop: "10px" }} key={index} disablePadding>
            <ListItemButton
              sx={{ paddingLeft: "30px" }}
              onClick={() => nav(item.route)}
            >
              <ListItemIcon
                sx={{ minWidth: "45px" }}
                className={
                  item.title === "HOME" ? "active-tab" : "inactive-tab"
                }
              >
                {item.title === "LOGOUT" ? (
                  item.icon
                ) : (
                  <img height={"20px"} src={item.icon} alt="" />
                )}
              </ListItemIcon>
              <ListItemText
                className={
                  item.title === "HOME" ? "active-tab" : "inactive-tab"
                }
                primary={item.title}
                primaryTypographyProps={{ fontSize: "11px" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        {user && (
          <ListItem sx={{ paddingTop: "10px" }} disablePadding>
            <ListItemButton
              sx={{ paddingLeft: "30px" }}
              onClick={() => {
                dispatch(clearGameData());
                dispatch(logoutUser());
                nav("/signin");
                toast.success("Logout");
              }}
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <IoMdLogOut color={"rgb(126 126 126 / 54%)"} size={23} />
              </ListItemIcon>
              <ListItemText
                color={"white"}
                primary={"LOGOUT"}
                primaryTypographyProps={{ fontSize: "11px" }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Stack direction={"row"} padding={"17px"}>
        {SideBarSocial.map((item, i) => {
          return (
            <IconButton
              sx={{ padding: "4px" }}
              key={i}
              onClick={() => handleOpenLink(item.link)}
            >
              {item.icon}
            </IconButton>
          );
        })}
      </Stack>
      <Stack
        spacing={"2"}
        direction={"row"}
        fontSize={"12px"}
        sx={{ paddingTop: "20px" }}
        justifyContent={"space-evenly"}
      >
        <Link color={"gray"} underline={"none"}>
          HELP
        </Link>
        <Link color={"gray"} underline={"none"}>
          TERMS & CONDITIONS
        </Link>
      </Stack>
    </Drawer>
  );
};

export default SideBar;
