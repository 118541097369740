import { Button, Input, Stack } from "@mui/material";
import React, { useState } from "react";
import { RiLock2Line } from "react-icons/ri";
import InputBg from "../../assets/images/input-bg.svg";
import { toast } from "react-hot-toast";
import { resetPassword } from "../../store/auth/actions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const token = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({ password: "", rePassword: "" });
  const handleSubmit = () => {
    if (values.password && values.rePassword) {
      if (values.password === values.rePassword) {
        //console.log(values.password)
        dispatch(
          resetPassword({
            password: values.password,
            token: token?.id,
            nav: navigate
          })
        );
      } else {
        toast.error("Password Values are not matched");
      }
    } else {
      toast.error("Enter Values");
    }
  };
  return (
    <Stack marginTop={"3em"} marginLeft={"50px"} height={"100%"}>
      <Stack width={"370px"} marginTop={"2rem"}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1rem"
          }}
        >
          <Stack>
            <RiLock2Line size={"18px"} color={"#bababa"} />
          </Stack>
          <Stack
            sx={{
              backgroundImage: `url(${InputBg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "67px",
              width: "80%",
              marginLeft: "15px"
            }}
          >
            <Input
              type={"password"}
              placeholder={"Password"}
              value={values.password}
              onChange={e => setValues({ ...values, password: e.target.value })}
              sx={{
                height: "70px",
                width: "80%",
                color: "white",
                border: "none",
                background: "transparent",
                ":after": {
                  borderBottom: "none"
                }
              }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1rem"
          }}
        >
          <Stack>
            <RiLock2Line size={"18px"} color={"#bababa"} />
          </Stack>
          <Stack
            sx={{
              backgroundImage: `url(${InputBg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "67px",
              width: "80%",
              marginLeft: "15px"
            }}
          >
            <Input
              type={"password"}
              placeholder={"Re-Enter Password"}
              value={values.rePassword}
              onChange={e =>
                setValues({ ...values, rePassword: e.target.value })
              }
              sx={{
                height: "70px",
                width: "80%",
                color: "white",
                border: "none",
                background: "transparent",
                ":after": {
                  borderBottom: "none"
                }
              }}
            />
          </Stack>
        </Stack>
        <Stack paddingLeft={"3.5em"}>
          <Stack
            gap={"10px"}
            width={"100%"}
            marginTop={"2rem"}
            flexDirection={"row"}
          >
            <Button
              sx={{
                paddingX: "25px",
                color: "#bababa",
                borderRadius: "10px",
                background: "#3E1F5B",
                border: "1px solid #FF6F32"
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
