import React, { useEffect, useState } from "react";
import { pages } from "./Data";
import { Stack } from "@mui/system";
import isElectron from "is-electron";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import language from "../../assets/images/language.svg";
import {
  clearDataSuccess,
  connectWallet,
  getNfts,
  setWalletAddress
} from "../../store/auth/actions";
import Web3IntegrationsTest from "../walletIntegration/Web3IntegrationsTest";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [address, setAddress] = useState();
  const [wallet, setWallet] = useState(false);

  const { user, token, walletAddress, socket, keyValidated } = useSelector(
    state => state.auth
  );

  if (socket) {
    if (isElectron()) {
      socket.on("removeMetaResponse", res => {
        setWallet(null);
      });
    }
  }

  if (socket) {
    socket.on("connectMetaMaskResponse", update => {
      setWallet(update);
      dispatch(setWalletAddress(update?.walletAddress))
    });
  }

  const onConnetWallet = () => {
    window.ipcRenderer.sendSync("connectwallet");
  };

  useEffect(() => {
    // "0x3a56CDC1D841629c4Fbb47b6445C9cE7DdEAf6d2"
    if (wallet && !keyValidated) {
      dispatch(
        getNfts({
          walletAddress: wallet
        })
      );
    }
  }, [dispatch, wallet, walletAddress]);

  useEffect(() => {
    if (address) {
      const handleWalletConnect = async () => {
        dispatch(
          connectWallet({
            walletAddress: address,
            token,
            socket,
            user
          })
        );
      };
      handleWalletConnect();
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {}, [address]);

  const handleDisconnectWallet = () => {
    //console.log("inside app handle Disconnect");
    if (isElectron()) {
      setWallet(null);
      socket.emit("removeMeta", {
        Authorization: `Bearer ${token}`,
        type: "app"
      });
      dispatch(clearDataSuccess());
    }
  };
  const handleOpenLink = link => {
    if (isElectron()) {
      if (link) {
        window.ipcRenderer.send("openHeaderLinks", {
          link
        });
      }
    } else {
      window.open(link, "_blank");
    }
  };
  return (
    <AppBar position="static" sx={{ bgcolor: "transparent" }}>
      <Container>
        <Toolbar disableGutters sx={{ width: "100%" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              {pages.map(page => (
                <Button
                  key={page}
                  sx={{
                    my: 2,
                    color: "white",
                    opacity: 0.42,
                    fontSize: "12px"
                  }}
                  onClick={() => handleOpenLink(page.link)}
                >
                  {page.text}
                </Button>
              ))}
              <Button
                sx={{ my: 2, color: "white", fontSize: "12px" }}
                endIcon={<img src={language} alt="" width={"70%"} />}
              >
                EN
              </Button>
            </Stack>
            {isElectron() ? (
              user ? (
                wallet ? (
                  <Button
                    onClick={handleDisconnectWallet}
                    sx={{
                      color: "#bababa",
                      borderRadius: "10px",
                      background: "#3E1F5B",
                      border: "1px solid #FF6F32"
                    }}
                  >
                    Disconnect Wallet
                  </Button>
                ) : (
                  <Button
                    onClick={onConnetWallet}
                    sx={{
                      color: "#bababa",
                      borderRadius: "10px",
                      background: "#3E1F5B",
                      border: "1px solid #FF6F32"
                    }}
                  >
                    Connect Wallet
                  </Button>
                )
              ) : (
                ""
              )
            ) : user ? (
              <Web3IntegrationsTest
                setAddress={setAddress}
                socketWallet={socket}
                user={user}
                token={token}
              />
            ) : (
              ""
            )}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
