import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'
import {createBlacklistFilter} from 'redux-persist-transform-filter' 

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['_persist'],
  transforms: [createBlacklistFilter('auth', ['socket'])]
}

const history = createBrowserHistory()

const persistedReducer = persistReducer(persistConfig, rootReducer(history))
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)
const persistor = persistStore(store)

export { store, persistor, history }
