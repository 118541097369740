import {
  AppBar,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  createTheme
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";

const NavBar = () => {
  const handleMinimize = () => {
    window.ipcRenderer.send("minimize");
  };
  const handleClose = () => {
    window.ipcRenderer.send("window-closed");
  };
  return (
    <AppBar
      className="titlebar"
      position="static"
      sx={{ background: "black", height: "fit-content" }}
    >
      <Toolbar
        sx={{
          justifyContent: "end",
          minHeight: "fit-content !important",
          paddingRight: "0px !important"
        }}
      >
        <IconButton
          color="inherit"
          className="button1 titlebar-button"
          aria-label="button 1"
          sx={{
            borderRadius: "0%",
            background: "rgb(255 255 255 / 15%)",
            backdropFilter: "blur(5px)",
            padding: "0px 10px",
            ":hover": {
              backgroundColor: "gray"
            }
          }}
          onClick={handleMinimize}
        >
          <RemoveIcon />
        </IconButton>
        <IconButton
          color="inherit"
          className="button2 titlebar-button"
          aria-label="button 2"
          sx={{
            borderRadius: "0%",
            background: "rgb(255 255 255 / 15%)",
            backdropFilter: "blur(5px)",
            padding: "0px 10px",
            ":hover": {
              backgroundColor: "#FF605C"
            }
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
