import { Stack, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-hot-toast";
import Gamefied from "../../assets/images/gamefied.png";


const StarzWallet = ({ setWallet, closeModal }) => {


  const handle99Wallet = async () => {
    if (window.starzwallet) {
      // Get provider from okxWallet
      const accounts = await window.starzwallet.request({
        method: "eth_requestAccounts"
      });
      setWallet(accounts[0]);
      closeModal(false);
    } else {
      toast.error("Install starzwallet");
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        className="button"
        onClick={handle99Wallet}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          p: "1em",
          cursor:'pointer'
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img src={Gamefied} alt="" width={"10%"} />
        <Typography height={"fit-content"} variant={"h4"}>
          Gamefied Wallet
        </Typography>
      </Stack>
    </>
  );
};

export default StarzWallet;
