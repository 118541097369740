import React from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  buildStyles,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { RadialSeparators } from "./RadialSeperators";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { useState } from "react";
import resumeIcon from "../../assets/images/resume.png";
import pauseIcon from "../../assets/images/pause.png";

export const Progress = ({
  progress,
  totalSize,
  donwloadedSize,
  downloadSpeed,
  isPaused,
  setIsPaused,
  handlePause
}) => {
  let width = 6;

  return (
    <Stack className="progreess wrapper" height={"100%"}>
      <Stack height={"100%"} position={"relative"}>
        <div className="outer-circle" />
        <div className="inner-circle" />
        <div className="inner-progress">
          <CircularProgressbarWithChildren
            value={progress}
            text={`${progress}%`}
            strokeWidth={width}
            styles={buildStyles({
              strokeLinecap: "butt",
              width: "80%",
              textColor: "white",
              textSize: "1.3em"
            })}
          >
            <RadialSeparators
              count={40}
              style={{
                background: "black",
                width: "4px",
                height: `${width}%`
              }}
            />
          </CircularProgressbarWithChildren>
        </div>
      </Stack>

      <Stack height={"100%"}>
        <Stack className="progress-divider" />
        <Stack direction={"row"} spacing={3}>
          <Stack className="download-size">
            {`${donwloadedSize} - ${totalSize}`}
          </Stack>
          {/* <Stack className="download-speed">{`${downloadSpeed}/s`}</Stack> */}
        </Stack>

        <Stack className="progress-bar-black">
          <div
            style={{
              width: progress + "%",
              position: "relative",
              height: "100%",
              overflow: "hidden"
            }}
          >
            <Stack className="progress-bar-glow" />
          </div>
        </Stack>
        <Stack className="play-pause">
          <IconButton onClick={handlePause}>
            {isPaused ? (
              <img src={resumeIcon} width={"60%"} />
            ) : (
              <img src={pauseIcon} width={"60%"} />
            )}
          </IconButton>
        </Stack>
      </Stack>
      <Stack className="progress-divider" />
      <Stack direction={"row"} spacing={3}>
        {/* <Stack className="download-size">
            {`${donwloadedSize} - ${totalSize}`}
          </Stack> */}
        <Stack className="download-speed">{`${downloadSpeed}/s`}</Stack>
      </Stack>
    </Stack>
  );
};
