import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import WalletModal from "../../reuseable/WalletModal";
import OkxWallet from "./okxWallet";
import MetaMaskWallet from "./MetaMaskWallet";
import WalletConnectWallet from "./WalletConnectWallet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearDataSuccess, connectWallet } from "../../store/auth/actions";
import StarzWallet from "./StarzWallet";
import BitKepp from "./BitKepp";
import ImmutableXComponent from "./imx";
export const Web3IntegrationsTest = ({
  setAddress,
  socketWallet,
  user,
  token
}) => {
  const [openWallet, setOpenWallet] = useState();
  const handleOpenWallet = () => setOpenWallet(true);
  const [wallet, setWallet] = useState();
  console.log("walet", wallet);
  //console.log("🚀 ~ file: Web3IntegrationsTest.jsx:23 ~ wallet:", wallet)
  const dispatchStore = useDispatch();
  useEffect(() => {
    if (wallet) {
      setAddress(wallet);
      socketWallet &&
        dispatchStore(
          connectWallet({
            walletAddress: wallet,
            token,
            socket: socketWallet,
            user
          })
        );
    }
  }, [wallet]);
  const iswalletConnect = localStorage.getItem("iswallatConnect");
  const handleDisconnect = async () => {
    if (window?.ethereum?.selectedAddress) {
      setWallet(null);
      socketWallet &&
        socketWallet.emit("removeMeta", {
          Authorization: `Bearer ${token}`,
          type: "web"
        });
      dispatchStore(clearDataSuccess());
    } else if (window?.okexchain?.selectedAddress) {
      window.okexchain.disconnect();
      setWallet(null);
      socketWallet &&
        socketWallet.emit("removeMeta", {
          Authorization: `Bearer ${token}`,
          type: "web"
        });

      dispatchStore(clearDataSuccess());
    }
  };
  useEffect(() => {
    if (socketWallet) {
      socketWallet.on("removeMetaResponse", res => {
        setWallet(null);
        dispatchStore(clearDataSuccess());
      });
    }
  }, [socketWallet]);

  return (
    <Stack>
      {wallet ? (
        <Button
          variant="outlined"
          className="button"
          type="button"
          onClick={handleDisconnect}
        >
          {`${wallet.slice(0, 5)}...${wallet.slice(-5)}`}
        </Button>
      ) : (
        <Button
          variant="outlined"
          className="button"
          onClick={handleOpenWallet}
          sx={{
            color: "#bababa",
            borderRadius: "10px",
            background: "#3E1F5B",
            border: "1px solid #FF6F32"
          }}
        >
          Connect Wallet
        </Button>
      )}
      <WalletModal
        handleClose={() => setOpenWallet(false)}
        open={openWallet}
        width={"50%"}
      >
        <ImmutableXComponent setWallet={setWallet} closeModal={setOpenWallet} />
        <OkxWallet setWallet={setWallet} closeModal={setOpenWallet} />
        <MetaMaskWallet setWallet={setWallet} closeModal={setOpenWallet} />
        <WalletConnectWallet setWallet={setWallet} closeModal={setOpenWallet} />
        <StarzWallet setWallet={setWallet} closeModal={setOpenWallet} />
        <BitKepp setWallet={setWallet} closeModal={setOpenWallet} />
      </WalletModal>
    </Stack>
  );
};

export default Web3IntegrationsTest;
