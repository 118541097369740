import { Button, Input, Stack } from "@mui/material";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import InputBg from "../../assets/images/input-bg.svg";
import { toast } from "react-hot-toast";
import { forgetPassword } from "../../store/auth/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({ email: "" });
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (values.email) {
      //console.log(values.email)
      dispatch(forgetPassword({ email: values.email, nav: navigate }));
    } else {
      toast.error("Enter values");
    }
  };
  return (
    <Stack marginTop={"3em"} marginLeft={"50px"} height={"100%"}>
      <Stack width={"370px"} marginTop={"2rem"}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1rem"
          }}
        >
          <Stack paddingBottom={"20px"}>
            <FaUser size={"18px"} color={"#bababa"} />
          </Stack>
          <Stack
            sx={{
              backgroundImage: `url(${InputBg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "67px",
              width: "80%",
              marginLeft: "15px"
            }}
          >
            <Input
              placeholder="Enter Recovery Email"
              value={values.email}
              onChange={e => setValues({ email: e.target.value })}
              sx={{
                height: "70px",
                width: "80%",
                color: "white",
                border: "none",
                background: "transparent",
                ":after": {
                  borderBottom: "none"
                }
              }}
            />
          </Stack>
        </Stack>
        <Stack paddingLeft={"3.5em"}>
          <Stack
            gap={"10px"}
            width={"100%"}
            marginTop={"2rem"}
            flexDirection={"row"}
          >
            <Button
              sx={{
                paddingX: "25px",
                color: "#bababa",
                borderRadius: "10px",
                background: "#3E1F5B",
                border: "1px solid #FF6F32"
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
