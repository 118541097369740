import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import isElectron from "is-electron";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DownloadApp from "../components/DownloadApp";
import { getAllGamesUser } from "../store/game/actions";
import { Progress } from "../components/progress/Progress";
import { formatBytes } from "../utils/helpers";
import UpdateModal from "../components/UpdateModal";
import packageJson from "../../package.json";
import { ScaleLoader } from "react-spinners";
import DownloadMultiApps from "../components/DownloadMultiApps";

const HomeScreen = () => {
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const [totalSize, setTotalSize] = useState();
  const [downloadedSize, setDownloadedSize] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [buffer, setBuffer] = useState(false);
  const silentDownload = useRef(true);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [gcpLink, setGcpLink] = useState();
  const handleClose = () => {
    setUpdateStatus(false);
  };

  const [releaseVersion, setReleaseVersion] = useState();

  useEffect(() => {
    dispatch(getAllGamesUser());
  }, [dispatch]);

  useEffect(() => {
    if (isElectron()) {
      window.ipcRenderer.send("get_user_details");
      window.ipcRenderer.on("message", function (event, text) {});
      window.ipcRenderer.on(
        "progress",
        (
          event,
          {
            progressPercentValue,
            downloadedValue,
            totalValue,
            downloadSpeedCalculated
          }
        ) => {
          setBuffer(false);
          let downloaded = formatBytes(downloadedValue);
          let total = formatBytes(totalValue);
          let speed = formatBytes(downloadSpeedCalculated);
          if (silentDownload.current === false) {
            setTotalSize(total);
            setDownloadedSize(downloaded);
            setDownloadProgress(progressPercentValue);
            setDownloadSpeed(speed);
          }
        }
      );
      window.ipcRenderer.on(
        "update-downloaded-complete",
        (event, { downloaded }) => {
          if (silentDownload.current === false) {
            window.ipcRenderer.send("quit-update");
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isElectron()) {
      const token = localStorage.getItem("token");
      setTimeout(() => {
        fetch(`https://launcherapi.katanainu.com/api/v1/launcher/getVersion`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(response => response.json())
          .then(releases => {
            const latestRelease = releases?.data?.version;
            setGcpLink(releases?.data?.gcpLink);
            const comparisonResult = latestRelease.localeCompare(
              packageJson.version,
              undefined,
              { numeric: true }
            );

            if (comparisonResult === 1) {
              setReleaseVersion(latestRelease);
              setUpdateStatus(true);
              // window.ipcRenderer.send("get_update");
            }
          })
          .catch(error => {
            console.error("Error fetching release information:", error);
          });
      }, 1000);
    }
  }, []);

  return (
    <Stack
      justifyContent={"space-between"}
      height={"100%"}
      position={"relative"}
    >
      <Stack
        width={"30%"}
        paddingX={"25px"}
        paddingY={"40px"}
        textAlign={"center"}
      >
        <Typography fontSize={"40px"} fontWeight={"600"}>
          KATANA INU
        </Typography>
        <Typography color={"#FFB62A"} variant={"h6"} fontWeight={"600"}>
          WEB3 NFT PC GAME
        </Typography>
        <Typography fontSize={"14px"} color={"#A7A7A7"}>
          Katana Inu is a (NFT) PC game with AAA graphics and limited NFTs. It
          is a third-person game that focuses on fast-paced sword and spell
          fighting. ⚔️⛩
        </Typography>
      </Stack>
      <Stack
        sx={{ paddingTop: "30px" }}
        c
        height={"13em"}
        position={"absolute"}
        bottom="0"
        width={"100%"}
      >
        {buffer ? (
          <Stack
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ScaleLoader color="#ff7922" />
          </Stack>
        ) : downloadProgress && downloadedSize && totalSize && downloadSpeed ? (
          <Progress
            totalSize={totalSize}
            donwloadedSize={downloadedSize}
            progress={downloadProgress}
            downloadSpeed={downloadSpeed}
          />
        ) : user && user.role === "tester" ? (
          <>
            <DownloadMultiApps />
          </>
        ) : (
          <>
            <DownloadApp />
          </>
        )}
      </Stack>
      <UpdateModal
        open={updateStatus}
        handleClose={handleClose}
        setBuffer={setBuffer}
        silentDownload={silentDownload}
        releaseVersion={releaseVersion}
        gcpLink={gcpLink}
      />
    </Stack>
  );
};

export default HomeScreen;
