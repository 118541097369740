import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import isElectron from "is-electron";

const CustomPathModal = ({
  open,
  handleClose,
  gameName,
  handleSubmitPathModal
}) => {
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "rgb(0,0,0)",
    boxShadow: 36,
    color: "white",
    p: 4,
    border: "1px solid white"
  };
  const [fieldValue, setFieldValue] = useState("");

  const handleGetCustomePath = () => {
    window.ipcRenderer.send("get-custom-path", { currentGameName: gameName });
  };
  useEffect(() => {
    if (isElectron()) {
      window.ipcRenderer.on("chosen-path", (event, { pathName }) => {
        setFieldValue(pathName);
      });
      window.ipcRenderer.send("global-path-find", {
        currentGameName: gameName
      });
      window.ipcRenderer.on("global-path-get", (event, { exists, path }) => {
        if (exists) {
          setFieldValue(path);
        }
      });
    }
  }, [gameName]);

  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "pink"
              },
              "&:hover fieldset": {
                borderColor: "yellow"
              },
              "&.Mui-focused fieldset": {
                borderColor: "green"
              }
            }
          }
        }
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Modal open={open}>
        <Stack sx={style} spacing={3} width={"100%"}>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <FormLabel sx={{ color: "#ffffff99" }}>
              Select Custome Path
            </FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                input: { color: "white" },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white !important",
                  outline: "none !important"
                }
              }}
              value={fieldValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ height: "2em" }}>
                    <IconButton>
                      <FolderCopyIcon
                        color="action"
                        sx={{ color: "white" }}
                        onClick={handleGetCustomePath}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              sx={{ width: "fit-content", alignSelf: "end", mt: "1em" }}
              variant="contained"
              onClick={() => handleSubmitPathModal(fieldValue)}
            >
              Done
            </Button>
          </FormControl>
          <IconButton
            sx={{
              color: "white",
              position: "absolute",
              top: "-1em",
              right: "0px"
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Modal>
    </ThemeProvider>
  );
};

export default CustomPathModal;
