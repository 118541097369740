import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";

import { Stack } from "@mui/system";
import isElectron from "is-electron";
import { FaUser } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RiLock2Line } from "react-icons/ri";
import {
  googelLoginUser,
  loginUser,
  validateUser
} from "../../store/auth/actions";
import InputBg from "../../assets/images/input-bg.svg";
import { Button, IconButton, Input, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";

import discord from "../../assets/images/discord.svg";
import DiscordLoginButton from "./DiscordLoginButton";
import { ScaleLoader } from "react-spinners";
import packageJson from "../../../package.json";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.auth);
  useEffect(() => {
    if (isElectron()) {
      window.ipcRenderer.on("login-success", (event, data) => {
        const userCredentials = jwtDecode(data?.data.split("?")[1]);
        dispatch(googelLoginUser({ email: userCredentials.email, navigate }));
      });
      window.ipcRenderer.on("autoLoginToken", (event, { pass }) => {
        const autoSignToken = pass;
        if (autoSignToken) {
          dispatch(
            validateUser({
              token: autoSignToken,
              version: packageJson.version,
              navigate
            })
          );
        }
      });
    }
  }, []);

  const [values, setValues] = useState({ email: "", password: "" });

  const handleSubmit = () => {
    if (values.email && values.password) {
      dispatch(
        loginUser({
          email: values.email,
          password: values.password,
          version: packageJson.version,
          navigate
        })
      );
    } else {
      toast.error("Enter values");
    }
  };

  const googleSuccess = response => {
    const userCredentials =
      jwtDecode != undefined ? jwtDecode(response.credential) : "";

    if (!response.hasOwnProperty("error")) {
      const link = document.createElement("a");
      link.href = `katana-inu://response=?${response.credential}&authtype=google-auth`;
      document.body.appendChild(link);
      link.click();
    }
    dispatch(googelLoginUser({ email: userCredentials.email, navigate }));
  };

  const handleDiscordLogin = () => {
    window.ipcRenderer.send("googleLogin");
    navigate("/signInWithDiscord");
  };

  return (
    <Stack marginTop={"20px"} marginLeft={"50px"} height={"100%"}>
      {isLoading ? (
        <Stack
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0,0,30,0.4)"
          }}
        >
          <ScaleLoader color="#ff7922" />
        </Stack>
      ) : (
        <Stack width={"370px"} marginTop={"2rem"}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "1rem"
            }}
          >
            <Stack paddingBottom={"20px"}>
              <FaUser size={"18px"} color={"#bababa"} />
            </Stack>
            <Stack
              sx={{
                backgroundImage: `url(${InputBg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: "67px",
                width: "80%",
                marginLeft: "15px"
              }}
            >
              <Input
                placeholder="Email"
                value={values.email}
                onChange={e => setValues({ ...values, email: e.target.value })}
                sx={{
                  height: "70px",
                  width: "80%",
                  color: "white",
                  border: "none",
                  background: "transparent",
                  ":after": {
                    borderBottom: "none"
                  }
                }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Stack paddingBottom={"20px"}>
              <RiLock2Line size={"18px"} color={"#bababa"} />
            </Stack>
            <Stack
              sx={{
                backgroundImage: `url(${InputBg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: "67px",
                width: "80%",
                marginLeft: "15px"
              }}
            >
              <Input
                type={"password"}
                placeholder={"Password"}
                value={values.password}
                onChange={e =>
                  setValues({ ...values, password: e.target.value })
                }
                sx={{
                  height: "70px",
                  width: "80%",
                  color: "white",
                  border: "none",
                  background: "transparent",
                  ":after": {
                    borderBottom: "none"
                  }
                }}
              />
            </Stack>
          </Stack>
          <Typography
            alignSelf={"end"}
            mr={"2em"}
            mt={"1em"}
            color={"#808080"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </Typography>
          <Stack paddingLeft={"40px"}>
            <Stack
              gap={"10px"}
              width={"100%"}
              marginTop={"2rem"}
              flexDirection={"row"}
            >
              <Button
                sx={{
                  paddingX: "25px",
                  color: "#bababa",
                  borderRadius: "10px",
                  background: "#3E1F5B",
                  border: "1px solid #FF6F32"
                }}
                onClick={handleSubmit}
              >
                {isLoading ? "Logging In..." : "LOG IN"}
              </Button>
              <Button
                onClick={() => navigate("/signup")}
                sx={{ color: "gray", paddingX: "25px" }}
              >
                SIGN UP
              </Button>
            </Stack>

            {/* <Stack marginTop={"20px"}>
            <Typography color={"#434343"} fontSize={"10px"}>
              LOG IN WITH
            </Typography>
            <Stack gap={"10px"} marginTop={"10px"} flexDirection={"row"}>
              {isElectron() ? (
                <Stack direction={"row"} spacing={1}>
                  <IconButton
                    sx={{
                      width: "fit-content",
                      padding: "8px",
                      background: "white",
                      border: "2px solid #3c88be",
                      height: "fit-content"
                    }}
                    onClick={() => window.ipcRenderer.send("googleLogin")}
                  >
                    <FcGoogle size={21} />
                  </IconButton>
                  <IconButton
                    sx={{
                      padding: "0 !important",
                      bgcolor: "white",
                      borderRadius: "50px",
                      width: "40px",
                      border: "1px solid blue"
                    }}
                    onClick={() => handleDiscordLogin()}
                  >
                    <img src={discord} alt="" width={"70%"} />
                  </IconButton>
                </Stack>
              ) : (
                <Stack direction={"row"} spacing={1}>
                  <GoogleLogin
                    type={"icon"}
                    shape={"circle"}
                    theme={"filled_blue"}
                    onSuccess={e => googleSuccess(e)}
                    onError={() => {}}
                    cookiePolicy
                  />
                  <IconButton
                    sx={{
                      padding: "0 !important",
                      bgcolor: "white",
                      borderRadius: "50px",
                      width: "40px",
                      border: "1px solid blue"
                    }}
                    href="https://discord.com/api/oauth2/authorize?client_id=1089808511623503933&redirect_uri=https%3A%2F%2Flauncher.katanainu.com%2F%23%2FsignInWithDiscord&response_type=code&scope=identify%20email"
                  >
                    <img src={discord} alt="" width={"70%"} />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Stack> */}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Login;
