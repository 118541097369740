import produce from "immer";
import isElectron from "is-electron";
import { toast } from "react-hot-toast";
import storage from "redux-persist/lib/storage";
import {
  STOP_LOADING,
  LOGIN_SUCCESS,
  START_LOADING,
  PIN_SUCCESS,
  ERROR,
  UPDATE_WALLET,
  CLEAR_DATA_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_OTP_SUCCESS,
  GET_NFTS_SUCCESS,
  VALIDATE_KEY_SUCCESS,
  VALIDATE_KEY_ERROR,
  NFT_START_LOADING,
  NFT_STOP_LOADING,
  KEY_START_LOADING,
  KEY_STOP_LOADING,
  GOOGLE_LOGIN_SUCCESS,
  SET_SOCKET_SUCCESS,
  LOGOUT,
  GET_NFTS_FAILED,
  VALIDATE_USER_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SIGNUP_OTP_RESET,
  SET_WALLET_ADDRESS
} from "./actionTypes";

const initialState = {
  user: null,
  nfts: null,
  nftsLoading: false,
  nftsError: null,
  token: null,
  EOS_access_token: null,
  EOS_refresh_token: null,
  otpToken: null,
  isLoading: false,
  keyValidated: false,
  keyValidatedLoading: false,
  walletAddress: null,
  signupOtpToken: null,
  keyError: null,
  socket: null,
  refreshToken: null
};

const Auth = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case NFT_START_LOADING:
      return {
        ...state,
        nftsLoading: true
      };
    case NFT_STOP_LOADING:
      return {
        ...state,
        nftsLoading: false
      };
    case KEY_START_LOADING:
      return {
        ...state,
        keyValidatedLoading: true
      };
    case KEY_STOP_LOADING:
      return {
        ...state,
        keyValidatedLoading: false
      };
    case CLEAR_DATA_SUCCESS:
      state.walletAddress = null;
      break;
    case ERROR:
      if (action?.payload?.response?.data?.data?.message) {
        toast.error(action.payload.response.data.data.message);
      } else if (action?.payload?.data?.data?.message) {
        toast.error(action.payload.data.data.message);
      } else if (action?.payload?.message) {
        toast.error(action?.payload?.message);
      } else {
        toast.error("Error");
      }
      break;

    case LOGIN_SUCCESS:
      toast.success("Success");
      localStorage.setItem("token", action?.payload?.data?.token);
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
      if (isElectron()) {
        window.ipcRenderer.send("set_user_details", {
          urlValue: "https://launcher.katanainu.com/",
          token: action?.payload?.data?.refreshToken
        });
        if (action?.payload?.data?.key) {
          state.keyValidated = true;
        }
      }
      state.token = action?.payload?.data?.token;
      state.user = action.payload.data.user;
      state.walletAddress = action.payload.walletAddress;
      break;

    // case LOGIN_SUCCESS_EOS:
    //   toast.success("Success EOS Login");
    //   localStorage.setItem(
    //     "EOS_access_token",
    //     action?.payload?.data?.access_token
    //   );
    //   // if (isElectron()) {
    //   //   window.ipcRenderer.send("set_user_details", {
    //   //     urlValue: "https://launcher.katanainu.com/",
    //   //     token: action?.payload?.data?.refreshToken
    //   //   });
    //   //   if (action?.payload?.data?.key) {
    //   //     state.keyValidated = true;
    //   //   }
    //   // }
    //   state.EOS_access_token = action?.payload?.data?.access_token;
    //   state.EOS_refresh_token = action?.payload?.data?.refresh_token;
    //   break;

    case VALIDATE_USER_SUCCESS:
      //console.log(action?.payload);
      localStorage.setItem("token", action?.payload?.data?.token);
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
      if (action?.payload?.data?.key) {
        state.keyValidated = true;
      }
      state.user = action.payload.data.user;
      state.token = action?.payload?.data?.token;
      // state.walletAddress = action?.payload?.data?.user?.walletAddress;
      break;
    case GOOGLE_LOGIN_SUCCESS:
      toast.success("Success");
      localStorage.setItem("token", action?.payload?.data?.token);
      localStorage.setItem("user", JSON.stringify(action.payload.data.data));

      state.token = action?.payload?.data?.token;
      state.user = action.payload.data.data;
      state.walletAddress = action.payload.walletAddress;
      break;
    case SIGNUP_SUCCESS:
      state.signupOtpToken = action.payload.data.token;
      sessionStorage.setItem("signup-otp-token", action.payload.data.token);
      break;
    // case SIGNUP_SUCCESS_EOS:
    //   toast.success("Account created on EOS Success");
    //   break;
    case FORGET_PASSWORD_SUCCESS:
      toast.success("Link has been Sent");
      break;
    case RESET_PASSWORD_SUCCESS:
      toast.success("Password Reset Successfully");
      break;
    case SIGNUP_OTP_SUCCESS:
      toast.success("Success");
      sessionStorage.removeItem("signup-otp-token");
      state.signupOtpToken = null;
      break;
    case SET_WALLET_ADDRESS:
      //console.log("action", action);
      state.walletAddress = action.payload.action;
      break;
    // state.walletAddress  =action.payload
    case UPDATE_WALLET:
      state.walletAddress = action.payload.data.walletAddress;
      break;
    case PIN_SUCCESS:
      state.otpToken = action?.payload?.data?.token;
      sessionStorage.removeItem("token");
      sessionStorage.setItem(
        "user",
        JSON.stringify(action?.payload?.data?.user)
      );

      state.user = action?.payload?.data?.user;
      break;
    case GET_NFTS_SUCCESS:
      //console.log("GET_NFTS_SUCCESS", action.payload);
      state.nfts = action.payload.data;
      toast.success("SUCCESS");
      break;
    case GET_NFTS_FAILED:
      //console.log("GET_NFTS_FAILED", action.payload.response.data.data.message);
      toast.error(action.payload.response.data.data.message);
      state.nftsError = action.payload.response.data.data.message;
      break;
    case VALIDATE_KEY_SUCCESS:
      state.keyValidated = true;
      toast.success("succcess");
      break;

    case VALIDATE_KEY_ERROR:
      state.keyError = true;
      break;

    case SET_SOCKET_SUCCESS:
      state.socket = action.payload;
      break;

    case LOGOUT:
      localStorage.removeItem("token");
      storage.removeItem("persist:root");
      if (isElectron()) {
        window.ipcRenderer.send("removeToken");
      }
      return {
        user: null,
        nfts: null,
        nftsLoading: false,
        nftsError: null,
        token: null,
        otpToken: null,
        isLoading: false,
        keyValidated: false,
        keyValidatedLoading: false,
        walletAddress: null,
        signupOtpToken: null,
        keyError: null,
        socket: null
      };

    case SIGNUP_OTP_RESET:
      state.signupOtpToken = null;
      break;
    default:
      break;
  }
}, initialState);

export default Auth;
