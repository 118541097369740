import { Stack, Typography } from "@mui/material";
import { ethers } from "ethers";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import Metamask from "../../assets/images/metamask.svg";

async function isConnected() {
  const accounts = await window.ethereum.request({ method: "eth_accounts" });
  if (accounts.length) {
    //console.log(`You're connected to: ${accounts[0]}`);
  } else {
    //console.log("Metamask is not connected");
  }
}
const MetaMaskWallet = ({ setWallet, closeModal }) => {
  useEffect(() => {
    isConnected();
  }, []);

  const handleMetaMask = async () => {
    if (window?.ethereum?.isMetaMask) {
      // Get provider from Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await window?.ethereum?.request({
        method: "eth_requestAccounts"
      });
      setWallet(accounts[0]);
      closeModal(false);
    } else {
      toast.error("Install Metamask");
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        className="button"
        onClick={handleMetaMask}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          p: "1em",
          cursor: "pointer"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img src={Metamask} alt="" width={"10%"} />
        <Typography height={"fit-content"} variant={"h4"}>
          Metamask
        </Typography>
      </Stack>
    </>
  );
};

export default MetaMaskWallet;
