import React from "react";
import Layout from "../../layout/Layout";
import PrivateRoutes from "./PrivateRoutes";
import HomeScreen from "../../screens/HomeScreen";
import Login from "../../components/login/Login";
// import MyAccounts from "../../screens/MyAccounts";
import SignUp from "../../components/login/SignUp";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HashRouter, Routes, Route } from "react-router-dom";
import DiscordCallbackScreen from "../../screens/DiscordCallbackScreen";
import ForgotPassword from "../../components/login/ForgotPassword";
import ResetPassword from "../../components/login/ResetPassword";

const MainRoutes = () => {
  
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoutes>
              <Layout>
                <HomeScreen />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/signin"
          element={
            <Layout>
              <GoogleOAuthProvider clientId="936874134492-r7lb4qtkqpd4v88ur8jg9avh9bvfgujh.apps.googleusercontent.com">
                <Login />
              </GoogleOAuthProvider>
            </Layout>
          }
        />
        <Route
          path="/signup"
          element={
            <Layout>
              <SignUp />
            </Layout>
          }
        />
        {/*  */}
         <Route
          path="/forgot-password"
          element={
            <Layout>
              <ForgotPassword />
            </Layout>
          }
        />
         <Route
          path="/resetPassword/:id"
          element={
            <Layout>
              <ResetPassword />
            </Layout>
          }
        />
        {/* <Route
          path="/myaccounts"
          element={
            <PrivateRoutes>
              <Layout>
                <MyAccounts />
              </Layout>
            </PrivateRoutes>
          }
        /> */}
        <Route
          path="/signInWithDiscord"
          element={
            <Layout>
              <DiscordCallbackScreen />
            </Layout>
          }
        />
      </Routes>
    </HashRouter>
  );
};

export default MainRoutes;
