import React from "react";
import { IconButton } from "@mui/material";
import discord from "../../assets/images/discord.svg";
import OAuth from "discord-oauth2";

const oauth = new OAuth({
  clientId: "1089808511623503933",
  clientSecret: "zRXKJYMqHCTPeAzO2w6LDRqeE8CEmFvw",
  redirectUri: "http://localhost:3000/auth/discord/callback"
});

const DiscordLoginButton = () => {
  const handleClick = () => {
    window.location.href =
      "https://discord.com/api/oauth2/authorize?client_id=1089808511623503933&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fdiscord%2Fcallback&response_type=code&scope=identify%20email";
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        padding: "0 !important",
        bgcolor: "white",
        borderRadius: "50px",
        width: "40px",
        border: "1px solid blue"
      }}
    >
      <img src={discord} alt="" width={"70%"} />
    </IconButton>
  );
};

export default DiscordLoginButton;
