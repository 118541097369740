import HomeIcon from "../../assets/images/sidebar/home.svg";
import GameIcon from "../../assets/images/sidebar/game.svg";
import RewardIcon from "../../assets/images/sidebar/reward.svg";
import ChatIcon from "../../assets/images/sidebar/chat.svg";
import FriendsIcon from "../../assets/images/sidebar/friends.svg";
import SettingIcon from "../../assets/images/sidebar/setting.svg";
import LeaderboardIcon from "../../assets/images/sidebar/leaderboard.svg";
import fb from "../../assets/images/sidebar/fb.svg";
import linkedin from "../../assets/images/sidebar/linkedin.svg";
import insta from "../../assets/images/sidebar/insta.svg";
import twit from "../../assets/images/sidebar/twit.svg";
import twitch from "../../assets/images/sidebar/twitch.svg";

export const SidebarData = [
  {
    title: "HOME",
    icon: HomeIcon,
    route: "/"
  },
  {
    title: "GAMES",
    icon: GameIcon,
    route: "/"
  },
  {
    title: "CHATS",
    icon: ChatIcon,
    route: "/"
  },
  {
    title: "FRIENDS",
    icon: FriendsIcon,
    route: "/"
  },
  {
    title: "LEADERBOARD",
    icon: LeaderboardIcon,
    route: "/"
  },
  {
    title: "REWARDS",
    icon: RewardIcon,
    route: "/"
  },
  {
    title: "SETTINGS",
    icon: SettingIcon,
    route: "/"
  }
];

export const SideBarSocial = [
  {
    icon: <img src={insta} alt="" width={"60%"} />,
    link: "https://www.instagram.com/katanainu/"
  },
  {
    icon: <img src={twit} alt="" width={"60%"} />,
    link: "https://twitter.com/katanainu"
  },
  {
    icon: <img src={fb} alt="" width={"60%"} />,
    link: "https://www.facebook.com/profile.php?id=100069383490342"
  },
  {
    icon: <img src={linkedin} alt="" width={"60%"} />,
    link: "https://www.linkedin.com/company/74030427"
  },
  { icon: <img src={twitch} alt="" width={"60%"} />, link: "" }
];

export const pages = [
  { text: "website", link: "https://katanainu.com/" },
  { text: "news", link: "https://medium.com/@katanainu" },
  { text: "discover", link: "" },
  { text: "marketplace", link: "https://kainu.io/" },
  { text: "minting nfts", link: "https://minting.katanainu.com/" }
];
