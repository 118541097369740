import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import MainRoutes from "./utils/routes/MainRoutes";
import isElectron from "is-electron";
import { useDispatch, useSelector } from "react-redux";
import { socketApp, socketWeb } from "./utils/socketConnections";
import { setSocketSuccess } from "./store/auth/actions";
import NavBar from "./components/navbar/NavBar";
import passportInstance from "./components/walletIntegration/passportInstance";

function App() {
  const { user, socket, token } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!socket) {
      let socketGenerated = null;
      if (isElectron() && user) {
        socketGenerated = socketApp(token);
      } else if (user) {
        socketGenerated = socketWeb(token);
      }
      if (socketGenerated) {
        dispatch(setSocketSuccess(socketGenerated));
      }
    }
    // eslint-disable-next-line
  }, [user]);
  useEffect(() => {
    if (window?.opener) {
      passportInstance.loginCallback();
    }
  }, []);

  return (
    <Stack height={"100vh"}>
      {isElectron() && <NavBar />}
      <MainRoutes />
    </Stack>
  );
}

export default App;
