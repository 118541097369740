import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import { CLEAR_GAME_DATA, GET_ALL_GAMES } from "./actionTypes";
import {
  stopLoading,
  startLoading,
  getAllGamesSuccess,
  getAllGamesFailed,
  clearGameDataSuccess
} from "./actions";

function* getGames() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/game/getAllGames");
    yield put(getAllGamesSuccess(response.data));
  } catch (error) {
    yield put(getAllGamesFailed(error));
  } finally {
    yield put(stopLoading());
  }
}
function* clearGameData({ payload }) {
  try {
    yield put(clearGameDataSuccess(payload));
  } catch (error) {
    //console.log(error);
  }
}
function* watchGameClearData() {
  yield takeLatest(CLEAR_GAME_DATA, clearGameData);
}

function* watchGetGames() {
  yield takeLatest(GET_ALL_GAMES, getGames);
}

export default function* gamesSaga() {
  yield all([fork(watchGetGames), fork(watchGameClearData)]);
}
