import axios from "axios";
import { liveAPILink, localAPILink } from "../utils/Variables";

const BASE_URL_API = `${liveAPILink}api/v1`;
const axiosConfig = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

axiosConfig.interceptors.request.use((config) => {
  // Check if signup-otp-token exists in sessionStorage
  const signupOtpToken = sessionStorage.getItem("signup-otp-token");
  if (signupOtpToken) {
    config.headers.Authorization = `Bearer ${signupOtpToken}`;
  } else {
    // Check if token exists in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

axiosConfig.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // Handle 401 unauthorized (e.g., logout logic)
      // logout();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
