import { Stack } from "@mui/system";
import React from "react";
// import okx from "../../assets/images/okx.svg";
import { Typography } from "@mui/material";
import { ethers } from "ethers";
// import { config, passport } from "@imtbl/sdk";
import passportInstance from "./passportInstance";
import { toast } from "react-hot-toast";

const ImmutableXComponent = ({ setWallet, closeModal }) => {
  const connect = async () => {
    try {
      console.log("all okay 2");

      const provider = passportInstance?.connectEvm();
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();

      // Request the user's Ethereum address
      const [userAddress] = await provider?.request({
        method: "eth_requestAccounts"
      });
      console.log(userAddress, "userAddress");

      localStorage.setItem("wallet address", userAddress);
      setWallet(userAddress);
      console.log("all okay 3");
      closeModal(false);
    } catch (error) {
      toast.error("Please check IMX wallet!");
      console.log("Error!", error);
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        className="button"
        onClick={connect}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          p: "1em",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          cursor: "pointer"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img
          src={"https://cryptologos.cc/logos/immutable-x-imx-logo.png"}
          alt=""
          width={"10%"}
        />
        <Typography height={"fit-content"} variant={"h4"}>
          IMX Wallet
        </Typography>
      </Stack>
    </>
  );
};

export default ImmutableXComponent;
