import { Stack } from "@mui/system";
import React from "react";
import bitget from "../../assets/images/bitget.svg";
import { Typography } from "@mui/material";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import { ToastContainer } from "react-bootstrap";

const BitKepp = ({ setWallet, closeModal }) => {
  const connect = async () => {
    if (window?.BitTerra?.isBitKeepChrome) {
    
      //console.log("inside", window);
      // Get provider from okxWallet
      const accounts = await window.bitkeep.ethereum.request({
        method: "eth_requestAccounts"
      });
      //console.log("🚀 ~ file: BitKepp.jsx:16 ~ connect ~ accounts:", accounts);
        setWallet(accounts[0]);
        closeModal(false);
    } else {
      toast.error("Install BitGet Wallet");
    }
  };

  return (
    <>
      <ToastContainer />
      <Stack
        direction={"row"}
        // component={Button}
        className="button"
        onClick={connect}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          p: "1em",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          cursor: "pointer"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img src={bitget} alt="" width={"10%"} />
        <Typography height={"fit-content"} variant={"h4"}>
          BitGet Wallet
        </Typography>
      </Stack>
    </>
  );
};

export default BitKepp;
