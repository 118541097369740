import { Stack } from "@mui/system";
import React from "react";
import okx from "../../assets/images/okx.svg";
import { Typography } from "@mui/material";
import { ethers } from "ethers";

const OkxWallet = ({ setWallet, closeModal }) => {
  const connect = async () => {
    if (window.okexchain) {
      // Get provider from okxWallet
      const provider = new ethers.providers.Web3Provider(window.okexchain);
      const accounts = await window.okexchain.request({
        method: "eth_requestAccounts"
      });
      setWallet(accounts[0]);
      closeModal(false);
    } else {
      // toast.error("Install Metamask");
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        // component={Button}
        className="button"
        onClick={connect}
        sx={{
          color: "#bababa",
          borderRadius: "10px",
          p: "1em",
          background: "#3E1F5B",
          border: "1px solid #FF6F32",
          width: "100%",
          cursor: "pointer"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <img src={okx} alt="" width={"10%"} />
        <Typography height={"fit-content"} variant={"h4"}>
          OKX Wallet
        </Typography>
      </Stack>
    </>
  );
};

export default OkxWallet;
