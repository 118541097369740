//imports
import React, { useEffect, useState } from "react";
import isElectron from "is-electron";
import "bootstrap/dist/css/bootstrap.min.css";
import { Progress } from "./progress/Progress";
import {
  formatBytes,
  keepLatestListener,
  keepLatestSender,
  shortenPath
} from "../utils/helpers";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import DownloadNowBtn from "../assets/images/downloadNow.svg";
import PlayButton from "../assets/images/btn-play.svg";
import { WalletAndKeysDlg } from "./WalletAndKeysDlg";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import { ScaleLoader } from "react-spinners";
import CustomPathModal from "./CustomPathModal";
import DeleteIcon from "@mui/icons-material/Delete";
import UninstallModal from "./UninstallModal";
import GameDropdown from "./gamsListing/GameDropdown";

const DownloadMultiApps = () => {
  // ================================== useState Start ================================== //
  const [flagStatus, setFlagStatus] = useState();
  const [selectedGameId, setSelectedGameId] = useState("");
  const [currentFile, setCurrentFile] = useState("");
  const [showDlg, setShowDlg] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [downloadedSize, setDownloadedSize] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [exeFileExists, setExeFileExists] = useState({
    exists: false,
    path: ""
  });
  const [fileSize, setfileSize] = useState();
  const [fileSizeBytes, setfileSizeBytes] = useState(0);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [allFilesDownloaded, setAllFilesDownloaded] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [customPathModal, setCustomPathModal] = useState(false);
  const [customPath, setCustomPath] = useState("");
  const [downloadUpdate, setDownloadUpdate] = useState(false);
  const [processID, setProcessID] = useState(null);
  const [fileExists, setFileExists] = useState({ exists: false, path: null });
  const [updateGame, setUpdateGame] = useState(false);
  const [uninstallModal, setUninstallModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uninstallModalLodaing, setUninstallModalLodaing] = useState(false);
  const [gameName, setGameName] = useState("");
  const [extracting, setExtracting] = useState(false);
  // ================================== useState End ================================== //

  // ================================== Store States Start ================================== //
  const { games } = useSelector(state => state.games);
  const { user, nfts, keyValidated, token } = useSelector(state => state.auth);
  // ================================== Store States End ================================== //

  // ================================== useRef Start ================================== //
  let downloadRef = useRef(null);
  // ================================== useRef End ================================== //

  // ================================== useEffect Start ================================== //
  useEffect(() => {
    if (isElectron()) {
      window.ipcRenderer.on("extracting", (_event, { status }) => {
        setExtracting(status);
      });
      keepLatestListener(window.ipcRenderer, "extracting");
      window.ipcRenderer.on("totalSize", (event, { result }) => {
        setfileSize(formatBytes(result));
        setfileSizeBytes(result);
      });
      keepLatestListener(window.ipcRenderer, "totalSize");
      window.ipcRenderer.on(
        "resolvedSize",
        (event, { value, totalSize, downloadSpeed, progress }) => {
          // setCurrentFile(filePath);
          setFetchingFiles(false);
          let speed = formatBytes(downloadSpeed);
          // const progress = downloadRef.current
          //   ? downloadRef.current - value
          //   : totalSize - value;
          // logMessage()
          downloadRef.current = progress;
          let total = formatBytes(totalSize);
          // let downloadedSizeRefactor = downloadRef.current;
          // let downloadedSize = downloadedSizeRefactor;
          let downloaded = formatBytes(value);

          setDownloadedSize(downloaded);
          setTotalSize(total);
          setDownloadSpeed(speed);

          setDownloadProgress(progress);
        }
      );
      keepLatestListener(window.ipcRenderer, "resolvedSize");
      window.ipcRenderer.on("errorFetchingFile", (event, { message }) => {
        toast.error(message);
        setFetchingFiles(false);
      });
      keepLatestListener(window.ipcRenderer, "errorFetchingFile");
      window.ipcRenderer.on("flagStatus", (event, { flag }) => {
        setFlagStatus(flag);
      });
      keepLatestListener(window.ipcRenderer, "flagStatus");
      window.ipcRenderer.on("paused", () => {
        setIsPaused(true);
      });
      keepLatestListener(window.ipcRenderer, "paused");
      window.ipcRenderer.on("resumed", () => {
        setIsPaused(false);
      });
      keepLatestListener(window.ipcRenderer, "resumed");
      window.ipcRenderer.on("processID", (event, { processID }) => {
        setProcessID(processID);
      });
      keepLatestListener(window.ipcRenderer, "processID");
    }
  }, []);
  useEffect(() => {
    if (isElectron()) {
      window.ipcRenderer.on("file-exists-exe", (event, { exists, path }) => {
        setExeFileExists({ exists, path });
      });
      keepLatestListener(window.ipcRenderer, "file-exists-exe");
      window.ipcRenderer.on("allFilesDownloaded", (event, { result }) => {
        setAllFilesDownloaded(true);
        setDownloadProgress(0);
        setDownloadedSize(null);
        setTotalSize(0);
        setFlagStatus("downloaded");
      });
      keepLatestListener(window.ipcRenderer, "allFilesDownloaded");
    }
  }, [exeFileExists.exists, games, uninstallModal]);
  useEffect(() => {
    if (downloadUpdate) {
      onDownload();
      setShowUpdate(false);
      setFileExists({ ...fileExists, exists: false });
      setExeFileExists({ ...exeFileExists, exists: false });
    }
    // eslint-disable-next-line
  }, [downloadUpdate]);
  useEffect(() => {}, [fileExists, exeFileExists]);
  useEffect(() => {
    if (isElectron()) {
      if (games) {
        window.ipcRenderer.send("checkGameVersion", {
          version: games?.version
        });
      }
      window.ipcRenderer.on("update-game-version", (event, { status }) => {
        setUpdateGame(status);
      });
    }
  }, [games]);
  useEffect(() => {
    if (flagStatus === "downloaded") {
      if (isElectron()) {
        if (games) {
          window.ipcRenderer.send("checkGameVersion", {
            version: games?.version
          });
        }
        window.ipcRenderer.on("update-game-version", (event, { status }) => {
          setUpdateGame(status);
        });
      }
    }
  }, [flagStatus]);
  // useEffect(() => {
  //   if (customPath) {
  //     handleDownload(customPath, flagStatus);
  //   }
  // }, [customPath]);
  useEffect(() => {
    setLoading(false);
  }, [updateGame, exeFileExists?.exists, flagStatus]);
  useEffect(() => {
    if (isElectron()) {
      if (games && selectedGameId) {
        const gameToDownload = games.find(item => {
          return item.gameId === selectedGameId;
        });
        setGameName(gameToDownload.name);
        window.ipcRenderer.send("file-exists-check", {
          exeFile: gameToDownload?.subPath,
          currentGameName: gameToDownload?.name
        });
        keepLatestSender(window.ipcRenderer, "file-exists-check");
        window.ipcRenderer.send("getFlagStatus", {
          currentGameName: gameToDownload?.name
        });
        setAllFilesDownloaded(false);
      }
    }
  }, [selectedGameId]);
  useEffect(() => {
    if (allFilesDownloaded) {
      if (games && selectedGameId) {
        const gameToDownload = games.find(item => {
          return item.gameId === selectedGameId;
        });
        setGameName(gameToDownload.name);
        window.ipcRenderer.send("file-exists-check", {
          exeFile: gameToDownload?.subPath,
          currentGameName: gameToDownload?.name
        });
        keepLatestSender(window.ipcRenderer, "file-exists-check");
        window.ipcRenderer.send("getFlagStatus", {
          currentGameName: gameToDownload?.name
        });
        setAllFilesDownloaded(false);
      }
    }
  }, [allFilesDownloaded]);

  useEffect(() => {
    if (isElectron()) {
      if (games && selectedGameId) {
        const gameToDownload = games.find(item => {
          return item.gameId === selectedGameId;
        });
        keepLatestListener(window.ipcRenderer, "deleted-game");
        window.ipcRenderer.on("deleted-game", (_event, { currentGameName }) => {
          if (currentGameName === gameToDownload?.name) {
            handleCloseUninstallModal();
            setCustomPath("");
            setUninstallModalLodaing(false);
            window.ipcRenderer.send("file-exists-check", {
              exeFile: gameToDownload?.subPath,
              currentGameName: gameToDownload?.name
            });
          }
        });
      }
    }
  }, [selectedGameId]);

  // ================================== useEffect End ================================== //

  // ================================== Handle Functions Start ================================== //
  const handleCloseUninstallModal = () => {
    setUninstallModal(false);
  };
  const handleCloseCustomePathModal = () => {
    setCustomPathModal(!customPathModal);
  };
  const handleCustomeFilepath = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      const gameToDownload = games.find(item => {
        return item.gameId === selectedGameId;
      });
      const currentGameName = gameToDownload?.name;
      setGameName(currentGameName);
      setCustomPathModal(true);
      // window.ipcRenderer.send("get-custom-path");
    } else {
      setShowDlg(true);
    }
  };
  const handleDownload = (chosenDirecory, flagStatus) => {
    logMessage(chosenDirecory);
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      if (games) {
        const gameToDownload = games.find(item => {
          return item.gameId === selectedGameId;
        });
        logMessage(gameToDownload);
        window.ipcRenderer.send("downloadGame", {
          // folderID: "1zsPv9URlhtI0VeE5tGCVelJK6bzgSvGT",
          folderID: gameToDownload?.googleDriveId,
          // fileSize: 72385149,
          fileSize: gameToDownload?.fileSize,
          // totalNoOfFiles: 136,
          totalNoOfFiles: gameToDownload?.totalFiles,
          gameVersion: gameToDownload?.version,
          baseDirectory: chosenDirecory,
          flagStatus: flagStatus,
          name: gameToDownload?.name
        });
        setFetchingFiles(true);
        setFlagStatus("downloading");
        window.ipcRenderer.send("updateFalgStatus", {
          flag: "downloading",
          currentGameName: gameToDownload?.name
        });
      }
    } else {
      setShowDlg(true);
    }
  };
  const handleUpdateGame = () => {
    window.ipcRenderer.send("updateGame", {
      exeFile: games?.subPath,
      folderID: games?.googleDriveId,
      fileSize: games?.fileSize,
      totalNoOfFiles: games?.totalFiles,
      gameVersion: games?.version,
      name: games?.name
    });
    setFetchingFiles(true);
    setUpdateGame(false);
    setFlagStatus("downloading");
    window.ipcRenderer.send("updateFalgStatus", {
      flag: "downloading"
    });
  };
  const handlePause = () => {
    if (!isPaused) {
      logMessage("is Paused");
      window.ipcRenderer.send("pause-download", {
        processID: processID
      });
    } else {
      logMessage("is Resumed");
      const gameToDownload = games.find(item => {
        return item.gameId === selectedGameId;
      });
      window.ipcRenderer.send("resume-download", {
        folderID: gameToDownload?.googleDriveId,
        // fileSize: 72385149,
        fileSize: gameToDownload?.fileSize,
        // totalNoOfFiles: 136,
        totalNoOfFiles: gameToDownload?.totalFiles
      });
    }
  };
  const handleDelete = () => {
    const gameToDownload = games.find(item => {
      return item.gameId === selectedGameId;
    });
    const currentGameName = gameToDownload?.name;
    setGameName(currentGameName);
    setUninstallModal(true);
    setAllFilesDownloaded(false);
  };
  const handleSubmitPathModal = fieldValue => {
    setCustomPath(fieldValue);
    handleDownload(fieldValue, flagStatus);
    handleCloseCustomePathModal();
  };
  // const handleResume = () => {
  //   window.ipcRenderer.send("resume-download");
  // };
  // ================================== Handle Functions End ================================== //

  // ================================== Functions Start ================================== //
  const logMessage = msg => {
    if (isElectron()) {
      // if (isDebugEnabled) {
      window.ipcRenderer.send("get-message", { msg: msg });
      // }
    }
  };
  const onDownload = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      if (games) {
        window.ipcRenderer.send("download", {
          payload: {
            url: games?.URL,
            properties: {
              filename: games?.Name,
              version: games?.version
            }
          }
        });
      } else {
        toast.error("No Games to download");
      }
    } else {
      setShowDlg(true);
    }
  };

  const launchExe = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      window.ipcRenderer.send("launch-exe", {
        token,
        exePath: exeFileExists.path
      });
    } else {
      setShowDlg(true);
    }
  };
  // ================================== Functions End ================================== //

  // ================================== Component Body Start ================================== //
  return (
    <Stack pl={3}>
      {isElectron() ? (
        <>
          {user && user?.role ? (
            <>
              {fetchingFiles ||
              (downloadProgress &&
                downloadedSize &&
                totalSize &&
                downloadSpeed) ? (
                ""
              ) : user && games ? (
                <GameDropdown
                  gameData={games}
                  selectedGameId={selectedGameId}
                  setSelectedGameId={setSelectedGameId}
                />
              ) : (
                ""
              )}
              {selectedGameId ? (
                extracting ? (
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <ScaleLoader color="#ff7922" />
                    <Typography>Extracting Files...</Typography>
                  </Stack>
                ) : updateGame &&
                  exeFileExists?.exists &&
                  flagStatus === "downloaded" ? (
                  <Stack width={"15em"}>
                    <Button
                      sx={{
                        color: "#bababa",
                        borderRadius: "10px",
                        background: "#3E1F5B",
                        border: "1px solid #FF6F32"
                      }}
                      onClick={handleUpdateGame}
                    >
                      {`Update File (${games?.version})`}
                    </Button>
                  </Stack>
                ) : fetchingFiles ? (
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <ScaleLoader color="#ff7922" />
                    <Typography>Fetching Files...</Typography>
                  </Stack>
                ) : flagStatus === "downloaded" && exeFileExists.exists ? (
                  <>
                    <Stack direction={"row"}>
                      <IconButton
                        sx={{ width: "fit-content" }}
                        onClick={launchExe}
                      >
                        <img alt="" height={"50px"} src={PlayButton} />
                      </IconButton>
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={() => handleDelete()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                    {flagStatus && exeFileExists?.path ? (
                      <Typography color={"white"} variant="caption" ptr={3}>
                        {`${shortenPath(exeFileExists?.path, gameName)}`}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </>
                ) : downloadProgress &&
                  downloadedSize &&
                  totalSize &&
                  downloadSpeed ? (
                  <>
                    <Progress
                      totalSize={totalSize}
                      donwloadedSize={downloadedSize}
                      progress={downloadProgress.toFixed(0)}
                      setIsPaused={setIsPaused}
                      isPaused={isPaused}
                      currentFile={currentFile}
                      downloadSpeed={downloadSpeed}
                      handlePause={handlePause}
                    />
                  </>
                ) : (
                  <>
                    <IconButton
                      sx={{ width: "fit-content" }}
                      onClick={() => handleCustomeFilepath()}
                    >
                      <img alt="" height={"50px"} src={DownloadNowBtn} />
                    </IconButton>
                  </>
                )
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          <WalletAndKeysDlg
            open={showDlg}
            handleClose={() => setShowDlg(false)}
          />
        </>
      ) : (
        ""
      )}
      <CustomPathModal
        setCustomPath={setCustomPath}
        handleClose={handleCloseCustomePathModal}
        open={customPathModal}
        gameName={gameName}
        handleSubmitPathModal={handleSubmitPathModal}
      />
      <UninstallModal
        handleClose={handleCloseUninstallModal}
        open={uninstallModal}
        filePath={exeFileExists?.path}
        setCustomPath={setCustomPath}
        gameName={gameName}
        loading={uninstallModalLodaing}
        setLoading={setUninstallModalLodaing}
      />
    </Stack>
  );
};
// ================================== Component Body End ================================== //

export default DownloadMultiApps;
