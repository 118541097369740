import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const PrivateRoutes = ({ children }) => {
  const { token } = useSelector(state => state.auth);
  const isAuthenticated = token;
  if (!isAuthenticated) {
    return <Navigate to={"/signin"} replace />;
  }
  return children;
};

export default PrivateRoutes;
