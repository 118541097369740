import { io } from "socket.io-client";
import { liveAPILink, localAPILink } from "./Variables";

export const socketWeb = token => {
  // const REACT_APP_SOCKET_URL = "https://launcherapi.katanainu.com";
  const socket = io(`${liveAPILink}`, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
      type: "web"
    }
  });
  // client-side
  socket.on("connect", () => {});

  socket.on("disconnect", () => {});
  return socket;
};

export const socketApp = token => {
  // const REACT_APP_SOCKET_URL = "https://launcherapi.katanainu.com";
  // //console.log(REACT_APP_SOCKET_URL);
  const socket = io(`${liveAPILink}`, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
      type: "app"
    }
  });
  // client-side
  socket.on("connect", () => {});

  socket.on("disconnect", () => {});
  return socket;
};
