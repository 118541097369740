// ================================== Imports Start ================================== //
import React, { useEffect, useState } from "react";
import isElectron from "is-electron";
import "bootstrap/dist/css/bootstrap.min.css";
import { Progress } from "./progress/Progress";
import { formatBytes, shortenPath } from "../utils/helpers";
import {
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from "@mui/material";
import DownloadNowBtn from "../assets/images/downloadNow.svg";
import PlayButton from "../assets/images/btn-play.svg";
import { WalletAndKeysDlg } from "./WalletAndKeysDlg";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import { ScaleLoader } from "react-spinners";
import CustomPathModal from "./CustomPathModal";
import DeleteIcon from "@mui/icons-material/Delete";
import UninstallModal from "./UninstallModal";
// ================================== Imports End ================================== //

const DownloadApp = () => {
  // ================================== Store States Start ================================== //
  const { games } = useSelector(state => state.games);
  const { user, nfts, keyValidated, token } = useSelector(state => state.auth);
  // ================================== Store States End ================================== //

  // ================================== useStates States ================================== //
  const [flagStatus, setFlagStatus] = useState();
  const [currentFile, setCurrentFile] = useState("");
  const [showDlg, setShowDlg] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [extracting, setExtracting] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [downloadedSize, setDownloadedSize] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [exeFileExists, setExeFileExists] = useState({
    exists: false,
    path: ""
  });
  const [fileSize, setfileSize] = useState();
  const [fileSizeBytes, setfileSizeBytes] = useState(0);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [allFilesDownloaded, setAllFilesDownloaded] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [customPathModal, setCustomPathModal] = useState(false);
  const [customPath, setCustomPath] = useState("");
  const [downloadUpdate, setDownloadUpdate] = useState(false);
  const [processID, setProcessID] = useState(null);
  const [fileExists, setFileExists] = useState({ exists: false, path: null });
  const [updateGame, setUpdateGame] = useState(false);
  const [uninstallModal, setUninstallModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentGameName, setCurrentGameName] = useState();
  // ================================== useStates States End ================================== //

  // ================================== useRef States Start ================================== //
  let downloadRef = useRef(null);
  // ================================== useRef States End ================================== //

  // ================================== Handle Functions Start ================================== //
  const handleCloseUninstallModal = () => {
    setUninstallModal(false);
  };
  const handleCloseCustomePathModal = () => {
    setCustomPathModal(!customPathModal);
  };
  const handleSubmitPathModal = fieldValue => {
    setCustomPath("");
    setCustomPath(fieldValue);
    handleCloseCustomePathModal();
  };
  const handleCustomeFilepath = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      setCustomPathModal(true);
      // window.ipcRenderer.send("get-custom-path");
    } else {
      setShowDlg(true);
    }
  };
  const handleDownload = (chosenDirecory, flagStatus) => {
    logMessage(chosenDirecory);
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      if (games) {
        logMessage(games);
        setCurrentGameName(games?.name);
        window.ipcRenderer.send("downloadGame", {
          // folderID: "1zsPv9URlhtI0VeE5tGCVelJK6bzgSvGT",
          folderID: games?.googleDriveId,
          // fileSize: 72385149,
          fileSize: games?.fileSize,
          // totalNoOfFiles: 136,
          totalNoOfFiles: games?.totalFiles,
          gameVersion: games?.version,
          baseDirectory: chosenDirecory,
          flagStatus: flagStatus,
          name: games?.name
        });
        setFetchingFiles(true);
        setFlagStatus("downloading");
        window.ipcRenderer.send("updateFalgStatus", {
          flag: "downloading",
          currentGameName: games?.name
        });
      }
    } else {
      setShowDlg(true);
    }
  };
  const handleUpdateGame = () => {
    window.ipcRenderer.send("updateGame", {
      exeFile: games?.subPath,
      folderID: games?.googleDriveId,
      fileSize: games?.fileSize,
      totalNoOfFiles: games?.totalFiles,
      gameVersion: games?.version,
      name: games?.name
    });
    setFetchingFiles(true);
    setUpdateGame(false);
    setFlagStatus("downloading");
    window.ipcRenderer.send("updateFalgStatus", {
      flag: "downloading"
    });
  };
  const handlePause = () => {
    if (!isPaused) {
      logMessage("is Paused");
      window.ipcRenderer.send("pause-download", {
        processID: processID
      });
    } else {
      logMessage("is Resumed");
      window.ipcRenderer.send("resume-download", {
        folderID: games?.googleDriveId,
        // fileSize: 72385149,
        fileSize: games?.fileSize,
        // totalNoOfFiles: 136,
        totalNoOfFiles: games?.totalFiles
      });
    }
  };
  const handleDelete = () => {
    setUninstallModal(true);
    setAllFilesDownloaded(false);
  };
  // const handleResume = () => {
  //   window.ipcRenderer.send("resume-download");
  // };
  // ================================== Handle Functions End ================================== //

  // ================================== useEffect State Start ================================== //
  useEffect(() => {
    if (isElectron()) {
      if (games) {
        window.ipcRenderer.send("getFlagStatus", {
          currentGameName: games?.name
        });
      }
      window.ipcRenderer.on("extracting", (_event, { status }) => {
        setExtracting(status);
      });
      window.ipcRenderer.on("totalSize", (event, { result }) => {
        setfileSize(formatBytes(result));
        setfileSizeBytes(result);
      });
      window.ipcRenderer.on(
        "resolvedSize",
        (event, { value, totalSize, downloadSpeed, progress }) => {
          // setCurrentFile(filePath);
          setFetchingFiles(false);
          let speed = formatBytes(downloadSpeed);
          // const progress = downloadRef.current
          //   ? downloadRef.current - value
          //   : totalSize - value;
          // logMessage()
          downloadRef.current = progress;
          let total = formatBytes(totalSize);
          // let downloadedSizeRefactor = downloadRef.current;
          // let downloadedSize = downloadedSizeRefactor;
          let downloaded = formatBytes(value);

          setDownloadedSize(downloaded);
          setTotalSize(total);
          setDownloadSpeed(speed);

          setDownloadProgress(progress);
        }
      );
      window.ipcRenderer.on("errorFetchingFile", (event, { message }) => {
        toast.error(message);
        setFetchingFiles(false);
      });
      window.ipcRenderer.on("flagStatus", (event, { flag }) => {
        setFlagStatus(flag);
      });
      window.ipcRenderer.on("paused", () => {
        setIsPaused(true);
      });
      window.ipcRenderer.on("resumed", () => {
        setIsPaused(false);
      });
      window.ipcRenderer.on("processID", (event, { processID }) => {
        setProcessID(processID);
      });
    }
  }, [games]);
  useEffect(() => {
    if (isElectron()) {
      console.log("games", games);
      if (games) {
        setCurrentGameName(games?.name);

        window.ipcRenderer.send("file-exists-check", {
          exeFile: games?.subPath,
          currentGameName: games?.name
        });
      }
      window.ipcRenderer.on("file-exists-exe", (event, { exists, path }) => {
        setExeFileExists({ exists, path });
      });
      window.ipcRenderer.on("allFilesDownloaded", (event, { result }) => {
        setAllFilesDownloaded(true);
        setDownloadProgress(0);
        setDownloadedSize(null);
        setTotalSize(0);
        setFlagStatus("downloaded");
      });
    }
  }, [exeFileExists.exists, allFilesDownloaded, games, uninstallModal]);
  useEffect(() => {
    if (downloadUpdate) {
      onDownload();
      setShowUpdate(false);
      setFileExists({ ...fileExists, exists: false });
      setExeFileExists({ ...exeFileExists, exists: false });
    }
    // eslint-disable-next-line
  }, [downloadUpdate]);
  useEffect(() => {}, [fileExists, exeFileExists]);
  useEffect(() => {
    if (isElectron()) {
      if (games) {
        window.ipcRenderer.send("checkGameVersion", {
          version: games?.version
        });
      }
      window.ipcRenderer.on("update-game-version", (event, { status }) => {
        setUpdateGame(status);
      });
    }
  }, [games]);
  useEffect(() => {
    if (flagStatus === "downloaded") {
      if (isElectron()) {
        if (games) {
          window.ipcRenderer.send("checkGameVersion", {
            version: games?.version
          });
        }
        window.ipcRenderer.on("update-game-version", (event, { status }) => {
          setUpdateGame(status);
        });
      }
    }
  }, [flagStatus]);
  useEffect(() => {
    if (customPath) {
      handleDownload(customPath, flagStatus);
    }
  }, [customPath]);
  useEffect(() => {
    setLoading(false);
  }, [updateGame, exeFileExists?.exists, flagStatus]);
  // ================================== useEffect State End ================================== //

  // ================================== Functions Start ================================== //
  const launchExe = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      window.ipcRenderer.send("launch-exe", {
        token,
        exePath: exeFileExists.path
      });
    } else {
      setShowDlg(true);
    }
  };
  const isDebugEnabled = false;
  const logMessage = msg => {
    if (isElectron()) {
      // if (isDebugEnabled) {
      window.ipcRenderer.send("get-message", { msg: msg });
      // }
    }
  };
  const onDownload = () => {
    if ((isElectron() && nfts) || (isElectron() && keyValidated)) {
      if (games) {
        window.ipcRenderer.send("download", {
          payload: {
            url: games?.URL,
            properties: {
              filename: games?.Name,
              version: games?.version
            }
          }
        });
      } else {
        toast.error("No Games to download");
      }
    } else {
      setShowDlg(true);
    }
  };
  // ================================== Functions End ================================== //

  // ================================== Component Body Start ================================== //
  return (
    <Stack pl={3}>
      {isElectron() ? (
        <>
          {user ? (
            extracting ? (
              <Stack alignItems={"center"} justifyContent={"center"}>
                <ScaleLoader color="#ff7922" />
                <Typography>Extracting Files...</Typography>
              </Stack>
            ) : updateGame &&
              exeFileExists?.exists &&
              flagStatus === "downloaded" ? (
              <Stack width={"15em"}>
                <Button
                  sx={{
                    color: "#bababa",
                    borderRadius: "10px",
                    background: "#3E1F5B",
                    border: "1px solid #FF6F32"
                  }}
                  onClick={handleUpdateGame}
                >
                  {`Update File (${games?.version})`}
                </Button>
              </Stack>
            ) : fetchingFiles ? (
              <Stack alignItems={"center"} justifyContent={"center"}>
                <ScaleLoader color="#ff7922" />
                <Typography>Fetching Files...</Typography>
              </Stack>
            ) : flagStatus === "downloaded" && exeFileExists.exists ? (
              <>
                <Stack direction={"row"}>
                  <IconButton sx={{ width: "fit-content" }} onClick={launchExe}>
                    <img alt="" height={"50px"} src={PlayButton} />
                  </IconButton>
                  <IconButton
                    sx={{ color: "white" }}
                    onClick={() => handleDelete()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                {flagStatus && exeFileExists?.path ? (
                  <Typography color={"white"} variant="caption" ptr={3}>
                    {`${shortenPath(exeFileExists?.path, "katana-inu")}`}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            ) : downloadProgress &&
              downloadedSize &&
              totalSize &&
              downloadSpeed ? (
              <>
                <Progress
                  totalSize={totalSize}
                  donwloadedSize={downloadedSize}
                  progress={downloadProgress.toFixed(0)}
                  setIsPaused={setIsPaused}
                  isPaused={isPaused}
                  currentFile={currentFile}
                  downloadSpeed={downloadSpeed}
                  handlePause={handlePause}
                />
              </>
            ) : (
              <>
                <IconButton
                  sx={{ width: "fit-content" }}
                  onClick={() => handleCustomeFilepath()}
                >
                  <img alt="" height={"50px"} src={DownloadNowBtn} />
                </IconButton>
              </>
            )
          ) : (
            ""
          )}
          <WalletAndKeysDlg
            open={showDlg}
            handleClose={() => setShowDlg(false)}
          />
        </>
      ) : (
        ""
      )}
      <CustomPathModal
        handleClose={handleCloseCustomePathModal}
        open={customPathModal}
        gameName={currentGameName}
        handleSubmitPathModal={handleSubmitPathModal}
      />
      <UninstallModal
        handleClose={handleCloseUninstallModal}
        open={uninstallModal}
        filePath={exeFileExists?.path}
        setCustomPath={setCustomPath}
      />
    </Stack>
  );
};
// ================================== Component Body End ================================== //

export default DownloadApp;
