import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import { Modal, TextField, IconButton, Typography } from "@mui/material";
import { toast } from "react-hot-toast";
import { validateKey } from "../store/auth/actions";
import Validate from "../assets/images/Validate.svg";
import Validating from "../assets/images/btn-validating.svg";
import { useDispatch, useSelector } from "react-redux";
import connectWallet from "../assets/images/connectWallet.svg";
import { ClipLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "rgb(0,0,0)",
  boxShadow: 36,
  color: "white",
  p: 4,
  border: "1px solid white"
};
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#ff7922"
};
export const WalletAndKeysDlg = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const [keyValue, setKeyValue] = useState("");
  const [keyTrue, setKeyTrue] = useState(false);

  const {
    nfts,
    keyValidated,
    nftsLoading,
    keyValidatedLoading,
    nftsError
  } = useSelector(state => state.auth);

  const onConnetWallet = () => {
    window.ipcRenderer.sendSync("connectwallet");
  };

  const handleValidate = () => {
    if (keyValue) {
      dispatch(validateKey({ key: keyValue }));
    } else {
      toast.error("Please enter a key");
    }
  };

  useEffect(() => {
    if (keyValidated) {
      setKeyTrue(keyValidated);
      handleClose();
    }
    // eslint-disable-next-line
  }, [keyValidated, keyTrue]);

  useEffect(() => {
    if (nfts) {
      handleClose();
    }
  }, [nfts]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box sx={style}>
          <Stack alignItems={"center"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {nftsLoading ? (
                <>
                  <ClipLoader
                    color={"#FF7922"}
                    loading={nftsLoading}
                    cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <Typography>Fetching Nfts...</Typography>
                </>
              ) : nftsError ? (
                <Typography>{nftsError}</Typography>
              ) : (
                // :
                // nfts === 0 ? (
                //   <>
                //     <Typography sx={{ textAlign: "center" }}>
                //       {nfts} NFTS Available
                //     </Typography>
                //     <Typography sx={{ textAlign: "center" }}>
                //       Validate with key if available
                //     </Typography>
                //   </>
                // )
                <IconButton
                  sx={{ width: "fit-content" }}
                  onClick={onConnetWallet}
                >
                  <img alt="" height={"50px"} src={connectWallet} />
                </IconButton>
              )}
            </Typography>
            <Typography id="modal-modal-description">Or</Typography>
            <TextField
              id="standard-basic"
              placeholder="Enter a Key"
              variant="outlined"
              value={keyValue}
              fullWidth
              sx={{
                color: "white",
                border: "1px solid white",
                background:
                  "linear-gradient(65.58deg, #F7C30B -11.84%, #03D5E8 98.75%)",
                ":focus-within": { border: "none", outline: "none" },
                "::placeholder": { color: "white" }
              }}
              onChange={e => setKeyValue(e.target.value)}
            />

            <IconButton
              onClick={handleValidate}
              sx={{ width: "fit-content", marginTop: "3px" }}
              disabled={keyValidatedLoading}
            >
              {keyValidatedLoading ? (
                <img alt="" height={"50px"} src={Validating} />
              ) : (
                <img alt="" height={"50px"} src={Validate} />
              )}
            </IconButton>
          </Stack>
        </Box>
      </div>
    </Modal>
  );
};
