import React from "react";
import range from "lodash/range";

const Separator = ({ turns, style}) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          position: "absolute",
          transform: `rotate(${turns}turn)`
        }}
      >
        <div style={style} />
      </div>
    </>
  );
};

export const RadialSeparators = ({
  count,
  style,

}) => {
  const turns = 1 / count;
  return range(count).map(index => (
    <Separator
      turns={index * turns}
      style={style}
    />
  ));
};
