import { combineReducers } from "redux";

// Authentication Module
import auth from "./auth/reducer";
import games from "./game/reducer";

const rootReducer = () =>
  combineReducers({
    auth,
    games
  });

export default rootReducer;
