import React, { useState } from "react";
import { Stack } from "@mui/system";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router";
import { RiLock2Line } from "react-icons/ri";
import { FaSlackHash } from "react-icons/fa";
import { Button, IconButton, Input, Typography } from "@mui/material";
import InputBg from "../../assets/images/input-bg.svg";
import OTPInput from "../OTPInput";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUser,
  signUpUserOtp,
  signUpUserOtpReset
} from "../../store/auth/actions";
import { toast } from "react-hot-toast";
import Changeemail from "../../assets/images/Changemail.svg";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signupOtpToken } = useSelector(state => state.auth);

  const [values, setValues] = useState({
    email: "",
    password: "",
    nickName: ""
  });

  const handlePin = otp => {
    dispatch(signUpUserOtp({ otp, navigate }));
  };

  const handleSubmit = () => {
    if (values.email && values.password) {
      dispatch(signUpUser(values));
    } else {
      toast.error("Enter values");
    }
  };

  const handleChangeEmail = () => {
    dispatch(signUpUserOtpReset());
  };
  return (
    <Stack marginTop={"20px"} marginLeft={"50px"}>
      <Stack width={"370px"} marginTop={"2.75rem"} spacing={3}>
        {signupOtpToken ? (
          <>
            <Typography paddingLeft={"13px"} variant="h6">
              Please Enter Otp From Your Email
            </Typography>
            <OTPInput handlePin={handlePin} />

            <IconButton variant="caption" onClick={handleChangeEmail}>
              <img alt="" height={"40px"} src={Changeemail} />
            </IconButton>
          </>
        ) : (
          <>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "1rem"
              }}
            >
              <Stack>
                <FaUser size={"18px"} color={"#bababa"} />
              </Stack>
              <Stack
                sx={{
                  backgroundImage: `url(${InputBg})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  height: "67px",
                  width: "80%",
                  marginLeft: "15px"
                }}
              >
                <Input
                  placeholder="Email"
                  value={values.email}
                  onChange={e =>
                    setValues({ ...values, email: e.target.value })
                  }
                  sx={{
                    height: "70px",
                    width: "80%",
                    color: "white",
                    border: "none",
                    background: "transparent",
                    ":after": {
                      borderBottom: "none"
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "1rem"
              }}
            >
              <Stack>
                <RiLock2Line size={"18px"} color={"#bababa"} />
              </Stack>
              <Stack
                sx={{
                  backgroundImage: `url(${InputBg})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  height: "67px",
                  width: "80%",
                  marginLeft: "15px"
                }}
              >
                <Input
                  type={"password"}
                  placeholder={"Password"}
                  value={values.password}
                  onChange={e =>
                    setValues({ ...values, password: e.target.value })
                  }
                  sx={{
                    height: "70px",
                    width: "80%",
                    color: "white",
                    border: "none",
                    background: "transparent",
                    ":after": {
                      borderBottom: "none"
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Stack>
                <FaSlackHash size={"18px"} color={"#bababa"} />
              </Stack>
              <Stack
                sx={{
                  backgroundImage: `url(${InputBg})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  height: "67px",
                  width: "80%",
                  marginLeft: "15px"
                }}
              >
                <Input
                  type={"text"}
                  placeholder={"Nick Name"}
                  value={values.nickName}
                  onChange={e =>
                    setValues({ ...values, nickName: e.target.value })
                  }
                  sx={{
                    height: "70px",
                    width: "80%",
                    color: "white",
                    border: "none",
                    background: "transparent",
                    ":after": {
                      borderBottom: "none"
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack paddingLeft={"40px"}>
              <Stack
                gap={"10px"}
                width={"100%"}
                marginTop={"2rem"}
                flexDirection={"row"}
              >
                <Button
                  onClick={() => navigate("/signin")}
                  sx={{ color: "gray", paddingX: "25px" }}
                >
                  LOG IN
                </Button>
                <Button
                  sx={{
                    paddingX: "25px",
                    color: "#bababa",
                    borderRadius: "10px",
                    background: "#3E1F5B",
                    border: "1px solid #FF6F32"
                  }}
                  onClick={handleSubmit}
                >
                  SIGN UP
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default SignUp;
