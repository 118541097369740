import {
  Button,
  IconButton,
  Modal,
  Stack,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { shortenPath } from "../utils/helpers";
import isElectron from "is-electron";
import SaveIcon from "@mui/icons-material/Save";

const UninstallModal = ({
  open,
  handleClose,
  filePath,
  gameName,
  loading,
  setLoading
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "rgb(0,0,0)",
    boxShadow: 36,
    color: "white",
    p: 2,
    border: "1px solid white"
  };
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              // - The Input-root, inside the TextField-root
              "& fieldset": {
                // - The <fieldset> inside the Input-root
                borderColor: "pink" // - Set the Input border
              },
              "&:hover fieldset": {
                borderColor: "yellow" // - Set the Input border when parent has :hover
              },
              "&.Mui-focused fieldset": {
                // - Set the Input border when parent is focused
                borderColor: "green"
              }
            }
          }
        }
      }
    }
  });

  const handleDeleteConsent = () => {
    if (isElectron()) {
      const pathValue = shortenPath(filePath, gameName);
      setLoading(true);
      setTimeout(() => {
        window.ipcRenderer.send("delete-game", {
          dirPath: pathValue,
          currentGameName: gameName
        });
      }, 3000);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal open={open} className="tetsttststte">
        <Stack sx={style} spacing={3} width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                alignSelf: "center"
              }}
            >
              Are you sure?
            </Typography>
            <IconButton
              sx={{
                mt: "0px",
                color: "white"
              }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {filePath && (
            <Typography
              textAlign={"center"}
            >{`Are you sure you want to uninstall: ${shortenPath(
              filePath,
              gameName
            )} ?`}</Typography>
          )}
          <Stack direction={"row"} justifyContent={"center"} spacing={2}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            {loading ? (
              <Button variant="outlined">Deleting...</Button>
            ) : (
              <Button variant="outlined" onClick={handleDeleteConsent}>
                Yes
              </Button>
            )}
          </Stack>
        </Stack>
      </Modal>
    </ThemeProvider>
  );
};

export default UninstallModal;
