import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const GameDropdown = ({ gameData, selectedGameId, setSelectedGameId }) => {
  const handleChange = event => {
    setSelectedGameId(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id="game-select-label"
        sx={{
          color: "white",
          "&.Mui-focused": {
            color: "white"
          }
        }}
      >
        Select Game to download
      </InputLabel>
      <Select
        labelId="game-select-label"
        value={selectedGameId}
        onChange={handleChange}
        label="Select Game"
        sx={{
          background:
            "linear-gradient(65.58deg, #F7C30B -11.84%, #03D5E8 98.75%)",
          ":focus-within": { border: "none", outline: "none" },
          "::placeholder": { color: "white" },
          width: "20vw"
        }} // Ensures the background is white
      >
        {gameData?.map(game => (
          <MenuItem key={game.gameId} value={game.gameId}>
            {game.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GameDropdown;
