import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import isElectron from "is-electron";
import React from "react";
import Update from "../assets/images/Update.svg";
import CloseIcon from "@mui/icons-material/Close";

const UpdateModal = ({
  open,
  handleClose,
  setBuffer,
  silentDownload,
  releaseVersion,
  gcpLink
}) => {
  const handleUpdateRequest = () => {
    handleClose();
    setBuffer(true);
    silentDownload.current = false;

    if (isElectron()) {
      // window.ipcRenderer.send("get_update");
      window.ipcRenderer.send("download-launcher", { gcpLink: gcpLink });
      silentDownload.current = false;
    }
  };

  const handleSilent = () => {
    handleClose();
    silentDownload.current = true;
    if (isElectron()) {
      window.ipcRenderer.send("get_update");
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "rgb(0,0,0)",
    boxShadow: 36,
    color: "white",
    p: 4,
    border: "1px solid white"
  };
  return (
    <>
      <Modal open={open}>
        <Stack sx={style} spacing={3}>
          <Typography
            variant="h5"
            textAlign={"center"}
          >{`New Launcher Update Available (v${releaseVersion})`}</Typography>
          <Stack direction={"row"} justifyContent={"center"}>
            {/* <Button onClick={handleSilent}>Cancel</Button> */}
            <IconButton onClick={handleUpdateRequest}>
              <img alt="" height={"50px"} src={Update} />
            </IconButton>
          </Stack>
          <IconButton
            sx={{
              color: "white",
              position: "absolute",
              top: "-1em",
              right: "0px"
            }}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Modal>
    </>
  );
};

export default UpdateModal;
