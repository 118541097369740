export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";

// export const LOGIN_EOS = "login_eos";
// export const LOGIN_SUCCESS_EOS = "login_success_eos";

export const VALIDATE_USER = "validate_user";
export const VALIDATE_USER_SUCCESS = "validate_user_success";

export const LOGOUT = "logout";

export const GOOGLE_LOGIN = "google_login";
export const GOOGLE_LOGIN_SUCCESS = "google_login_success";

export const FORGET_PASSWORD = "forget_password";
export const FORGET_PASSWORD_SUCCESS = "forget_password_success";

export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";

export const SIGNUP = "signup";
export const SIGNUP_SUCCESS = "signup_success";
// //sign-up EOS
// export const SIGNUP_EOS = "signup_eos";
// export const SIGNUP_SUCCESS_EOS = "signup_success_eos";

export const SIGNUP_OTP = "signup_otp";
export const SIGNUP_OTP_SUCCESS = "signup_otp_success";

export const SIGNUP_OTP_RESET = "signup_otp_reset";

export const PIN = "pin";
export const PIN_SUCCESS = "pin_success";

export const GET_NFTS = "get_nfts";
export const GET_NFTS_SUCCESS = "get_nfts_success";
export const GET_NFTS_FAILED = "get_nfts_failed";

export const VALIDATE_KEY = "validate_key";
export const VALIDATE_KEY_SUCCESS = "validate_key_success";
export const VALIDATE_KEY_ERROR = "validate_key_error";

export const UPDATE = "update";
export const UPDATE_WALLET = "update_wallet";

export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";

export const NFT_STOP_LOADING = "nft_stop_loading";
export const NFT_START_LOADING = "nft_start_loading";

export const SET_SOCKET_SUCCESS = "set_socket_success";

export const KEY_STOP_LOADING = "key_stop_loading";
export const KEY_START_LOADING = "key_start_loading";

export const CLEAR_DATA = "clear_data";
export const CLEAR_DATA_SUCCESS = "clear_data_success";
export const ERROR = "error";

export const SET_WALLET_ADDRESS = "set_wallet_address";
