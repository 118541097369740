import produce from "immer";
import {
  STOP_LOADING,
  START_LOADING,
  GET_ALL_GAMES_SUCCESS,
  GET_ALL_GAMES_FAILED,
  CLEAR_GAME_DATA_SUCCESS
} from "./actionTypes";

const initialState = {
  games: null
};

const Games = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };

    case GET_ALL_GAMES_SUCCESS:
      const user = JSON.parse(localStorage.getItem("user"));
      console.log("🚀 ~ Games ~ action?.payload:", action?.payload);
      if (user?.role && user?.role === "tester") {
        state.games = action?.payload?.data?.data;
      } else {
        state.games = action?.payload?.data?.data;
      }
      break;
    case CLEAR_GAME_DATA_SUCCESS:
      state.games = null;
      break;
    case GET_ALL_GAMES_FAILED:
      break;
    default:
      break;
  }
}, initialState);

export default Games;
