export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const shortenPath = (fullPath, targetFolder) => {
  const index = fullPath.indexOf(targetFolder);
  if (index !== -1) {
    const shortenedPath = fullPath.substring(0, index + targetFolder.length);
    return shortenedPath;
  }
  return fullPath; // Return the original path if the target folder is not found
};

export const keepLatestListener = (ipcRenderer, eventName) => {
  // Retrieve all listeners for the event
  const listeners = ipcRenderer.listeners(eventName);
  // If there are listeners, remove them all
  if (listeners.length > 0) {
    listeners.forEach(listener => {
      ipcRenderer.removeListener(eventName, listener);
    });
    // Add back the latest listener (the last one in the array)
    const latestListener = listeners[listeners.length - 1];
    ipcRenderer.on(eventName, latestListener);
  }
};

export const keepLatestSender = (ipcRenderer, eventName) => {
  // Retrieve all listeners for the event
  const listeners = ipcRenderer.listeners(eventName);
  // If there are listeners, remove them all
  if (listeners.length > 0) {
    listeners.forEach(listener => {
      ipcRenderer.removeListener(eventName, listener);
    });
    // Add back the latest listener (the last one in the array)
    const latestListener = listeners[listeners.length - 1];
    ipcRenderer.send(eventName, latestListener);
  }
};
