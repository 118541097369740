import {
  GET_ALL_GAMES,
  GET_ALL_GAMES_SUCCESS,
  STOP_LOADING,
  START_LOADING,
  GET_ALL_GAMES_FAILED,
  CLEAR_GAME_DATA,
  CLEAR_GAME_DATA_SUCCESS
} from "./actionTypes";

export const getAllGamesUser = data => {
  return {
    type: GET_ALL_GAMES,
    payload: data
  };
};

export const getAllGamesSuccess = data => {
  return {
    type: GET_ALL_GAMES_SUCCESS,
    payload: data
  };
};
export const getAllGamesFailed = data => {
  return {
    type: GET_ALL_GAMES_FAILED,
    payload: data
  };
};

export const clearGameData = data => ({
  type: CLEAR_GAME_DATA,
  payload: {
    action: data
  }
});

export const clearGameDataSuccess = data => ({
  type: CLEAR_GAME_DATA_SUCCESS,
  payload: {
    action: data
  }
});

export const startLoading = data => ({
  type: START_LOADING,
  payload: data
});

export const stopLoading = data => ({
  type: STOP_LOADING,
  payload: {
    action: data
  }
});
