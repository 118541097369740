export const GET_ALL_GAMES = "get_all_games";
export const GET_ALL_GAMES_SUCCESS = "get_all_games_success";
export const GET_ALL_GAMES_FAILED = "get_all_games_failed";

export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";

export const CLEAR_GAME_DATA = "clear_game_data";
export const CLEAR_GAME_DATA_SUCCESS = "clear_game_data_success";
export const ERROR = "error";
